import {
  GelRow,
  GelCol,
  GelTextInput,
  GelForm,
  GelButton
} from "@tal-gel/components";

import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchPolicyDetails } from "../../store/policy/actions";
import { useWindowSize } from "../../custom-hooks/customHooks";
import { pushNewCTAEvent } from "../../adobeAnalytics";

const SearchBox = (state: any) => {
  const [inputValue, setInputValue] = useState("");
  const [shouldDisabled, setShouldDisabled] = useState(false);
  const size = useWindowSize();
  const onSubmit = () => {
    if (inputValue === undefined || inputValue === null) {

      const policySearchInput = document.querySelector<HTMLInputElement>('input[name="policySearch"]');
      state.fetchPolicy(policySearchInput?.value)
    }
    else state.fetchPolicy(inputValue);
  };

  const onSubmitResetQuote = () => {
    if (inputValue === undefined || inputValue === null) {

      const policySearchInput = document.querySelector<HTMLInputElement>('input[name="policySearch"]');
      state.fetchPolicy(policySearchInput?.value)
    }
    else state.fetchPolicy(inputValue);

    // Adobe Analytics Data layer -> pushing to digitalData.events
    pushNewCTAEvent("Reset Quote", "Button", "Navigation Bar")
  }
  const onChange = (event: any) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    setInputValue(
      state?.policyResponse?.data?.Quote?.QuoteRefNo?.split("_")[0]
    );
  }, [state?.policyResponse?.data?.Quote?.QuoteRefNo, size?.width]);


  useEffect(() => {
    setTimeout(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const policyNumber = new URL(window.location.href).searchParams.get(
        "policyNumber"
      );

      if (policyNumber == null) { }
      if (window.self === window.top) {
        if (policyNumber !== null) {
          setShouldDisabled(true);
          state.fetchPolicy(policyNumber);
        }
      }
    }, 1000);
  }, []);

  return (

    <GelForm
      labelAtTop
      disableOnSubmit
      preventSubmitOnInvalid
      onSubmit={onSubmit}
      // style={{marginTop: 15}}
    >
      <GelRow gutter={"medium"}>
        <GelCol sm={5} md={12} lg={12}>
          {shouldDisabled ? (
            <GelTextInput
              name="policySearch"
              value={inputValue}
              onChange={onChange}
              onSuffixIconClick={onSubmit}
              required
              requiredErrorMsg="Policy number is required"
              disabled={true}
            />
          ) : (
            <GelTextInput
              name="policySearch"
              suffixIcon={"Search"}
              value={inputValue}
              onChange={onChange}
              onSuffixIconClick={onSubmit}
              required
              requiredErrorMsg="Policy number is required"
            />
          )}
        </GelCol>
        <GelCol sm={5} md={12} lg={12} style={{
          marginTop:
            "10px",
        }}>
          <GelButton
            primary
            medium
            //onClick={GoBackToTACForm}
            // disabled={quoteIllustrationBtnDisabled}
            onClick={onSubmitResetQuote}
            stretch={true}
            iconLeft="Refresh"
          >
            Reset Quote
          </GelButton>
        </GelCol>
      </GelRow>
    </GelForm>
  );
};

const mapStateToProps = (state: any) => ({
  policyResponse: state.policyResponse,
});
const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchPolicy: (policyResponse: any) =>
      dispatch(fetchPolicyDetails(policyResponse)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);