import CardElements from "./CardElements";
import { GelRow, GelCol, GelContainerLite } from "@tal-gel/components";

interface CardFrameData {
  cover: any;
  list: any;
  UpdateQuoteFn: any;
  columnSize?: number;
  policyData?: any;
  inruleDataState? : any;
}

export const shouldUIObjectBeAdded = (
  UIObject: any,
  parentObject: any,
  goupedUIOjects: any[]
) => {
  if (UIObject.ObjectType === "RadioButton") {
    var data = parentObject?.UIObjects?.filter(
      (item: any) => item.GroupName === UIObject.GroupName
    );
    if (goupedUIOjects.find((item: any) => item === UIObject.GroupName)) {
      UIObject = null;
    } else {
      goupedUIOjects.push(UIObject.GroupName);
      UIObject = data;
    }
  }
  return UIObject;
};

const groupBy = (array: any, key: any) => {
  // Return the end result
  return array?.reduce((result: any, currentValue: any) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

const CardFrame = ({
  cover,
  list,
  UpdateQuoteFn,
  columnSize,
  policyData,
  inruleDataState
}: CardFrameData) => {
  const framedUIOjects = Object.values(groupBy(list, "FrameName"));
  let columnNo = 0;

  for (const obj in framedUIOjects) {
    columnNo++;
  }

  const goupedUIOjects = new Array();

  return (
    <GelCol sm={6} md={12} lg={columnSize == null ? 6 : 12}>
      <GelContainerLite gutter="small">
        <GelRow gutter="small">
          {columnNo === 1
            ? Object.values(framedUIOjects).map(
                (frame: any, rowIndex: number) => {
                  return frame.map((UIObject: any, colIndex: number) => {
                    UIObject = shouldUIObjectBeAdded(
                      UIObject,
                      cover,
                      goupedUIOjects
                    );
                    return UIObject === null ? (
                      <></>
                    ) : (
                      <CardElements
                        UIObjects={UIObject}
                        // key={`${rowIndex}-${colIndex}`}
                        UpdateQuoteFn={UpdateQuoteFn}
                        parentObject={cover}
                        policyData={policyData}
                        inruleDataState={inruleDataState}
                      />
                    );
                  });
                }
              )
            : Object.values(framedUIOjects).map(
                (frame: any, rowIndex: number) => {
                  return (
                    <GelRow gutter="large" key={rowIndex}>
                      <GelCol sm={6} md={12} lg={12}>
                        {frame.map((UIObject: any, colIndex: number) => {
                          UIObject = shouldUIObjectBeAdded(
                            UIObject,
                            cover,
                            goupedUIOjects
                          );

                          return UIObject === null ? (
                            <></>
                          ) : (
                            <GelCol lg={12}>
                              <CardElements
                                // key={`${rowIndex}-${colIndex}`}
                                UIObjects={UIObject}
                                UpdateQuoteFn={UpdateQuoteFn}
                                parentObject={cover}
                                policyData={policyData}
                              />
                            </GelCol>
                          );
                        })}
                      </GelCol>
                    </GelRow>
                  );
                }
              )}
        </GelRow>
      </GelContainerLite>
    </GelCol>
  );
};
export default CardFrame;
