export const mapRegExp = (UIObject: any): any => {
  return handleTextFieldMapping(UIObject?.Value, UIObject);
};

export const validateRegExp = (textInputValue: any, UIObject: any) => {
  return handleTextFieldMapping(textInputValue, UIObject);
};

const handleTextFieldMapping = (value: any, UIObject: any) => {
  if (UIObject?.IsChanged && UIObject?.Message && UIObject?.Message?.length) {
    const message = UIObject?.Message;
    if (message[message.length - 1]?.Type === "Error") {
      return true;
    }
  }
  return false;
};
