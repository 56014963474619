import {
  GelContainerLite,
  GelRow,
  GelCol,
  GelAccordion,
  GelBoxLayout,
  GelColumnLayout,
  GelButton,
} from "@tal-gel/components";

import styled, { css } from "styled-components";

import { useState } from "react";
import CardFrame from "../shared/CardFrame";
import { handleUIChanges } from "../shared/UIElement";

const CustomGelButtonRemove = styled(GelButton)(
  (props) => css`
    position: absolute !important;
    margin-top: 7px !important;
    margin-left: 85% !important;
    z-index: 9999;
    @media (max-width: 1450px) {
      margin-left: 74% !important;
    }
  `
);

interface lifeProp {
  life: any;
  UpdateQuoteFn: any;
  id: string;
}
const LifeCardDetails = ({ life, UpdateQuoteFn, id }: lifeProp) => {
  const [open, setOpen] = useState(false);

  const lifeInsuredName = life?.UIObjects.find(
    (item: any) => item.Name === "LifeInsuredName"
  )?.Value;

  const list = life.UIObjects.filter(
    (item: any) => item.FrameName === "LifeInsuredDetails"
  );
  const listA = list.filter(
    (item: any) => item.LabelGridCell.substr(0, 1) === "A"
  );
  const listB = list.filter(
    (item: any) => item.LabelGridCell.substr(0, 1) === "B"
  );
  const lifeCardButtonUIObjects = life.UIObjects.filter(
    (item: any) => item?.GroupName === "GroupAddNewCover"
  );
  const lifeCardRemoveButtonUIObject = life.UIObjects.filter(
    (item: any) => item?.Name === "RemoveLife"
  )[0];

  return (
    <GelRow id={id} gutter={"small"}>
      <GelCol sm={2} md={12} lg={12}>
        {lifeCardRemoveButtonUIObject && lifeCardRemoveButtonUIObject?.Label && (
          <CustomGelButtonRemove
            id={`${id}-${lifeCardRemoveButtonUIObject?.Id}-${lifeCardRemoveButtonUIObject?.ObjectType}-${lifeCardRemoveButtonUIObject?.Name}`}
            primary
            medium
            onClick={() => {
              if (process.env?.REACT_APP_ENV_Stage === "true") {
                console.log("TODO invoke remove life cover logic here...");
              }
            }}
            disabled={lifeCardRemoveButtonUIObject?.IsDisabled}
          >
            {lifeCardRemoveButtonUIObject?.Label}
          </CustomGelButtonRemove>
        )}
        <GelAccordion
          style={{ marginBottom: "10px" }}
          title={`Edit (${lifeInsuredName}) details`}
          removeContentPadding
        >
          <GelContainerLite gutter={{ vertical: "medium" }}>
            <GelBoxLayout>
              <CardFrame
                cover={life}
                list={listA}
                UpdateQuoteFn={UpdateQuoteFn}
              />
              <CardFrame
                cover={life}
                list={listB}
                UpdateQuoteFn={UpdateQuoteFn}
              />
            </GelBoxLayout>
            <GelRow gutter="small">
              {lifeCardButtonUIObjects.map((item: any, index: number) => {
                return (
                  <GelColumnLayout
                    key={index}
                    gutter="medium"
                    style={{ marginTop: "10px" }}
                  >
                    <GelButton
                      primary
                      medium
                      disabled={item.IsDisabled}
                      stretch="true"
                      onClick={(e: any) =>
                        handleUIChanges(e, item, UpdateQuoteFn, life)
                      }
                    >
                      {item.Label}
                    </GelButton>
                  </GelColumnLayout>
                );
              })}
            </GelRow>
          </GelContainerLite>
        </GelAccordion>
      </GelCol>
    </GelRow>
  );
};

export default LifeCardDetails;
