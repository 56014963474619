import styled, { css } from "styled-components";
import { useState, useEffect } from "react";
import {
  GelContainerLite,
  GelRowLayout,
  GelButton,
  GelLabel,
  GelCol,
  GelFormField,
  GelRow
} from "@tal-gel/components";

import SearchBox from "./SearchBox";
import AssociatedPolicies from "../AssociatedPolicies/AssociatedPolicies";
import ToggleSwitch from "./ToggleSwitch";

import LoaderComponent from "../Loader";
import { DOWNLOAD_QUOTE_ILLUSTRATION } from "../shared/messages/Messages";
import { pushNewCTAEvent } from "../../adobeAnalytics";


interface QueryParams {
  policyNumber: string;
  clientId: string;
  distributorClientNo: string;
  distributorNo: string;
  appRefNo: string;
  lifeNo: string;
  productSeries: string;
  policyOrigin: string;
}

interface SideNavData {
  policyData: any;
  getQuoteIllustrationDocument: any;
  quoteIllustrationBtnDisabled: boolean;
  isLoading: boolean;
  // shouldOnlyQueCardBeShown: any;
  // policyNumber: any;
}
const SideNav = ({
  policyData,
  getQuoteIllustrationDocument,
  quoteIllustrationBtnDisabled,
  isLoading,
}: // shouldOnlyQueCardBeShown,
  // policyNumber
  SideNavData) => {
  const onClickGetProjection = (evt: any) => { };

  // 👇️ initial value of empty string (first option)
  const [isAdviserHide, setisAdviserHide] = useState("false");
  const [shouldPolicySearchLabelBeShown, setShouldPolicySearchLabelBeShown] = useState(true);
  const [shouldAdviserSwitchBeShown, setShouldAdviserSwitchBeShown] = useState(true);
  const [shouldButtonBackBeShown, setShouldButtonBackBeShown] = useState(false);
  const [queryParams, setQueryParams] = useState<QueryParams>({
    policyNumber: "",
    clientId: "",
    distributorClientNo: "",
    distributorNo: "",
    appRefNo: "",
    lifeNo: "",
    productSeries: "",
    policyOrigin: "",
  });

  //Added to capture the Show/Hide ToggleSwtichValue
  const callToggleSwitchHandler = (data: any) => {
    // console.log(`switch: ${data}`)
    setisAdviserHide(data);
  };

  // Check if request has come from TAC (external) or internal 
  const isRequestExternal = (policyData: any): boolean => {
    const spanForUserGroups = document.getElementById('ebqAppUrl'); // means external user
    if (spanForUserGroups && policyData) {
      return false  // if external return false to hide policy search label 
    } else return true
  }

  const downloadQuoteClick = () => {
    // Adobe Analytics Data layer -> pushing to digitalData.events
    pushNewCTAEvent("Download Quote", "Button", "Navigation Bar")
    getQuoteIllustrationDocument(isAdviserHide)
  };

  const GoBackToTACForm = () => {
    //hard coded for demo
    // if(queryParams.policyNumber == "1863945")
    // {
    //   // window.open(`https://adviser-dev1.retail.tal.com.au/dashboard/policy-details/request-existing-business-quote?policyNumber=${queryParams.policyNumber}&clientId=${queryParams.clientId}&distributorClientNo=${queryParams.distributorClientNo}&distributorNo=${queryParams.distributorNo}&appRefNo=${queryParams.appRefNo}&lifeNo=${queryParams.lifeNo}&productSeries=${queryParams.productSeries}&policyOrigin=${queryParams.policyOrigin}`,
    //   // "_blank");
    //   window.open(`https://adviser-dev1.retail.tal.com.au/dashboard/policy-details/request-existing-business-quote?policyNumber=1863945&clientId=b9e5af79-7c2b-4444-a118-bdc6eb29f311&distributorClientNo=974806&distributorNo=64451&appRefNo=&lifeNo=&productSeries=Series%2037`,
    //   "_self");

    // }
    // else{
    //   window.open(`https://adviser-dev1.retail.tal.com.au/dashboard/policy-details/request-existing-business-quote?policyNumber=${queryParams.policyNumber}`,
    //   "_self");
    // }

    const queryParams: QueryParams = {
      policyNumber: new URL(window.location.href).searchParams.get("policyNumber") as string,
      clientId: new URL(window.location.href).searchParams.get("clientId") as string,
      distributorClientNo: new URL(window.location.href).searchParams.get("distributorClientNo") as string,
      distributorNo: new URL(window.location.href).searchParams.get("distributorNo") as string,
      appRefNo: new URL(window.location.href).searchParams.get("appRefNo") as string,
      lifeNo: new URL(window.location.href).searchParams.get("lifeNo") as string,
      productSeries: new URL(window.location.href).searchParams.get("productSeries") as string,
      policyOrigin: new URL(window.location.href).searchParams.get("policyOrigin") as string,
    };
    window.location.href = `/dashboard/policy-details/request-existing-business-quote?policyNumber=${queryParams.policyNumber}&clientId=${queryParams.clientId}&distributorClientNo=${queryParams.distributorClientNo}&distributorNo=${queryParams.distributorNo}&appRefNo=${queryParams.appRefNo}&lifeNo=${queryParams.lifeNo}&productSeries=${queryParams.productSeries}&policyOrigin=${queryParams.policyOrigin}`;
    // window.open(`https://adviser-dev1.retail.tal.com.au/dashboard/policy-details/request-existing-business-quote?policyNumber=${queryParams.policyNumber}&clientId=${queryParams.clientId}&distributorClientNo=${queryParams.distributorClientNo}&distributorNo=${queryParams.distributorNo}&appRefNo=${queryParams.appRefNo}&lifeNo=${queryParams.lifeNo}&productSeries=${queryParams.productSeries}&policyOrigin=${queryParams.policyOrigin}`,"_self");

    // Adobe Analytics Data layer -> pushing to digitalData.events
    pushNewCTAEvent("Request Manual Quote", "Button", "Navigation Bar")
  };

  useEffect(() => {
    setTimeout(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const policyNumber = new URL(window.location.href).searchParams.get(
        "policyNumber"
      );
      if (policyNumber !== null) {
        setShouldButtonBackBeShown(true);
      }

      const queryStringNew = window.location.search;

      // Use URLSearchParams to parse the query string
      const params = new URLSearchParams(queryStringNew);

      // Create an object to hold the parsed parameters
      const parsedParams: any = {};

      // Iterate through the parameters and populate the object
      params.forEach((value, key) => {
        parsedParams[key] = value;
      });
      // Update the state with the parsed parameters
      setQueryParams(parsedParams);
    }, 1000);
  }, []);

  // Update to show/hide the policy number label 
  useEffect(() => {
    setTimeout(() => {
      // console.log(policyData !== undefined && queryParams.policyNumber !== undefined)    
      if (policyData !== undefined) { 
        setShouldPolicySearchLabelBeShown(isRequestExternal(policyData));
        setShouldAdviserSwitchBeShown(isRequestExternal(policyData));
      } 

    }, 1000); 
  }, [policyData]);

  return (
    <GelContainerLite gutter="medium">
      <GelRowLayout gutter="medium">
        {/* shouldOnlyQueCardBeShown={shouldOnlyQueCardBeShown} */}
        
        {/* Hide “Policy Search” label for External  */}
        {shouldPolicySearchLabelBeShown ? (
          <GelLabel>
            Policy Search
          </GelLabel>
        ) : <></>
        }
        <SearchBox />
      </GelRowLayout>
      {/* <GelRowLayout gutter="large"> */}
      {policyData ? (
        <>
          {isRequestExternal(policyData)}
          <GelRowLayout gutter="medium">
            <GelCol sm={5} md={12} lg={12} style={{
            }}>
              <AssociatedPolicies />
              {isLoading && (
                <LoaderComponent
                  overlayDescription={DOWNLOAD_QUOTE_ILLUSTRATION}
                />
              )}

            </GelCol>
            <GelCol sm={5} md={12} lg={12} style={{
              marginLeft: '4%'
            }}>
              <GelRow gutter={"medium"}>
                < GelButton
                  primary
                  medium
                  onClick={() => downloadQuoteClick()}
                  disabled={quoteIllustrationBtnDisabled}
                  style={{ width: '97%', marginBottom: '8%'}}
                >
                  Download Quote
                </GelButton>
              </GelRow>
              {shouldAdviserSwitchBeShown ? (
                <GelRow gutter={"medium"}>
                  <ToggleSwitch
                    rightLabel={"Include Adviser Details"}
                    callback={callToggleSwitchHandler}
                  />
                </GelRow>
              ) : <></>
              }
            </GelCol>
          </GelRowLayout>
          <br></br>
          {shouldButtonBackBeShown && (
            <GelRowLayout gutter="medium">
              <GelButton
                primary
                medium
                onClick={GoBackToTACForm}
                // disabled={quoteIllustrationBtnDisabled}
                stretch={true}
              >
                Request Manual Quote
              </GelButton>
            </GelRowLayout>
          )}
        </>
      ) : (
        <></>
      )
      }
      {/* </GelRowLayout> */}
    </GelContainerLite >
  );
};

export default SideNav;
