import {
  GelContainerLite,
  GelRow,
  GelCol,
  GelBoxLayout,
} from "@tal-gel/components";

import CardFrame from "../shared/CardFrame";

interface QuoteDetailsData {
  quote: any;
  inruleDataState: any;
}

const QuoteDetails = ({ quote,inruleDataState }: QuoteDetailsData) => {
  const UIObjects = quote?.UIObjects.filter(
    (item: any) => item.FrameName !== "AssociatedPolicy"
  );
  const listA = UIObjects?.filter(
    (item: any) => item.LabelGridCell.substr(0, 1) === "A"
  );
  const listB = UIObjects?.filter(
    (item: any) => item.LabelGridCell.substr(0, 1) === "B"
  );

  return (
    <GelRow gutter={"small"}>
      <GelCol sm={2} md={12} lg={12}>
        <GelBoxLayout>
          <CardFrame cover={quote} inruleDataState={inruleDataState} list={listA} UpdateQuoteFn={null} />
          <CardFrame cover={quote} inruleDataState={inruleDataState} list={listB} UpdateQuoteFn={null} />
        </GelBoxLayout>
      </GelCol>
    </GelRow>
  );
};

export default QuoteDetails;
