import React from "react";
import { Provider } from "react-redux";
import { useState } from "react";

import { Route, useHistory, Switch, BrowserRouter } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import { GlobalStyles } from "./themes/Global";
import { ThemeContainer, ThemeButton } from "./themes/ThemeSwitcher.styled";
import { ThemeProvider } from "styled-components";
import { light, dark, blue, green, brown, pink } from "./themes/Theme.styled";

import "./App.css";
import config from "./OktaConfig";
import Home from "./Home";
import store from "./store/store";
import ResolutionAlert from "./components/ResolutionAlert/ResolutionAlert";

import styled from "styled-components";
import { connect } from "react-redux";
import QuoteCard from "./components/QuoteCard/QuoteCard";

export const ThemeWrapper = styled.div`
  z-index: 99999;
  position: relative;
`;

const oktaAuth = new OktaAuth(config.oidc);

const App = (state: any) => {
  const [authRequiredModalOpen, setAuthRequiredModalOpen] =
    React.useState(false);

  const history = useHistory(); // example from react-router
  const [selectedTheme, setSelectedTheme] = useState(light);
  const errors = Object.keys(state?.policyResponse?.errors || {});
  const [isThemeEnabled, setIsThemeEnabled] = useState(
    process.env.REACT_APP_ThemeEnabled === "true"
  );
  const isPolicyNumberExistInURLFromExternalCall = new URL(window.location.href).searchParams.get(
    "policyNumber"
  );
  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const ebqRestoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  const customAuthHandler = async () => {
    const previousAuthState =
      oktaAuth?.authStateManager?.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState?.isAuthenticated) {
      // App initialization stage
      await triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      setAuthRequiredModalOpen(true);
    }
  };

  const handleThemeChange = (theme: any) => {
    setSelectedTheme(theme);
  };

  return (
    <Provider store={store}>
{isPolicyNumberExistInURLFromExternalCall === null ?
<>
      <ResolutionAlert />
      <GlobalStyles />
      {/* <Home /> */}
      
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={ebqRestoreOriginalUri}
      >

        <BrowserRouter forceRefresh>
          <Switch>
            <Route
              path="/login/callback"
              render={() => (
                <>
                  <LoginCallback />
                  <Home />
                </>
              )}
            />
            {process.env.REACT_APP_AuthenticationIsRequired === "true" ? (
              <SecureRoute path="/" exact component={Home} />
            ) : (
              <Route path="/" exact component={Home} />
            )}
          </Switch>
        </BrowserRouter>
      </Security>
      </>
       : <>
            <GlobalStyles />
      	   <Home />
       </>
       }
    </Provider>
  );
};

export const mapStateToProps = (state: any) => ({
  policyResponse: state.policyResponse,
});

export default connect(mapStateToProps)(App);
