import { AnyAction, Reducer } from "redux";
import { inRuleInitialState, IPolicyData } from "../state";
import {
  GET_POLICY_DETAILS,
  GET_POLICY_DETAILS_SUCCESS,
  GET_POLICY_DETAILS_ERROR,
  INITIALIZE_INRULE_ENGINE,
  TRIGGER_INRULE_VALIDATION,
  UPDATE_QUOTE,
  UPDATE_QUOTE_ERROR,
  UPDATE_QUOTE_SUCCESS,
  GET_AssociatedPolicy_DETAILS,
  GET_AssociatedPolicy_DETAILS_SUCCESS,
  GET_AssociatedPolicy_DETAILS_ERROR,
} from "./actions";
import { IHeader, IRequest, IQuoteResponse } from "../../models/memberDetails";

export interface IPasPolicyState {
  Header: IHeader;
  Request: IRequest;
  getPasPolicyDetails: any;
  data?: any;
}

export interface IQuoteServiceState {
  Header: IHeader;
  Response: IQuoteResponse;
}

export interface IRootPolicyState {
  policy: IPasPolicyState;
  quote: IQuoteServiceState;
}

export interface IPasPolicyState1 {
  isLoading?: boolean;
  data?: IPolicyData;
  errors?: any;
}

export interface IRootPolicyState1 {
  policy: IPasPolicyState1;
}

export interface InRuleState {}

export const inRuleReducer: Reducer<InRuleState, AnyAction> = (
  state = inRuleInitialState.InRule,
  action: AnyAction
): any => {
  switch (action.type) {
    case INITIALIZE_INRULE_ENGINE:
      return {
        ...state,
      };

    case TRIGGER_INRULE_VALIDATION:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export const policyReducer: Reducer<IPasPolicyState1, AnyAction> = (
  state: any = {},
  action: AnyAction
): IPasPolicyState1 => {
  switch (action.type) {
    case GET_POLICY_DETAILS:
      state = null;
      return {
        ...state,
        isLoading: true,
      };

    case GET_POLICY_DETAILS_SUCCESS:
      console.log(
        "process",
        process,
        "stage",
        process.env?.REACT_APP_ENV_Stage
      );
      if (process.env?.REACT_APP_ENV_Stage === "true") {
        console.log(
          "policy details from PAS polilcy search ======>",
          action?.response?.data?.getpolicydetails,
          "Stage===",
          process,
          process.env?.REACT_APP_ENV_Stage
        );
      }
      return {
        ...state,
        data: {
          ...action?.response?.data?.getpolicydetails,
        },
        errors: {
          ...action?.response?.data?.getpolicydetails?.Messages,
        },
        isLoading: false,
      };

    case GET_POLICY_DETAILS_ERROR:
      return {
        ...state,
        errors: {
          ...action?.response?.data?.getpolicydetails?.Messages,
        },
        isLoading: false,
      };

    case GET_AssociatedPolicy_DETAILS:
      state = null;
      return {
        ...state,
        isLoading: true,
      };

    case GET_AssociatedPolicy_DETAILS_SUCCESS:
      return {
        ...state,
        data: {
          ...action?.response?.data?.getAssociatedPolicyDetails,
        },
        errors: {
          ...action?.response?.data?.getAssociatedPolicyDetails?.Messages,
        },
        isLoading: false,
      };

    case GET_AssociatedPolicy_DETAILS_ERROR:
      return {
        ...state,
        errors: {
          ...action.response?.data?.getAssociatedPolicyDetails?.Messages,
        },
        isLoading: false,
      };

    case UPDATE_QUOTE:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_QUOTE_SUCCESS:
      // DGOTO 3970 added condition to differntiate add cove
      let updatedData: any;
      if (action?.response?.isUpdateQuoteModel) {
        updatedData = action?.response?.data?.getupdatedquotemodeldetails;
      } else {
        updatedData = action?.response?.data?.getupdatedpremiumdetails;
      }
      return {
        ...state,
        data: {
          ...updatedData,
        },
        errors: {
          ...updatedData?.Messages,
        },
        isLoading: false,
      };

    case UPDATE_QUOTE_ERROR:
      // DGOTO 3970 added condition to differntiate add cove
      let updatedErrorData: any;
      if (action?.response?.isUpdateQuoteModel) {
        updatedErrorData = action?.response?.data?.getupdatedquotemodeldetails;
      } else {
        updatedErrorData = action?.response?.data?.getupdatedpremiumdetails;
      }
      return {
        ...state,
        errors: {
          ...updatedErrorData.Messages,
        },
        isLoading: false,
      };

    default:
      return state;
  }
};
