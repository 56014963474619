import { GelContainerLite, GelIcon } from "@tal-gel/components";
import { useState, useEffect } from "react";
import { useWindowSize } from "../custom-hooks/customHooks";
import styled from "styled-components";

interface Error {

}


interface Errors {
  errors?: any[];
}

const CustomErrorAlert = styled.div`
  width: 100%;
  float: left;
  clear: left;
  zindex: 99999;
  overflow: hidden;
  overflow-x: auto;
  display: grid;
  grid-gap: 20px;
  position: fixed;
  user-select: none;
  top: 0px;
  left: 0px;
`;

const Icon = styled.div`
  margin-right: 10px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  border-radius: 8px;
`;

const Content = styled.div`
  flex: 1;
`;

const ContentParagraph = styled.p`
  padding: 0;
  margin: 0;
`;

const CloseIcon = styled.div`
  margin-left: 10px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
  transition: all 100ms ease-in-out;
  cursor: pointer;
  z-index: 999999;
`;

const Toast = styled.div`
  padding: 15px;
  color: #383535;
  display: flex;
  align-items: flex-start;
  line-height: 1.6;
  font-size: 14px;
  z-index:99999999 !important;
`;

const ErrorHandler = ({ errors = [] }: Errors) => {
  const open = errors?.length > 0;
  const [openErrorAlert, setOpenErrorAlert] = useState(open);

  // const [openErrorAlertWarning, setOpenErrorAlertWarning] = useState(open);
  const size = useWindowSize();

  const errorsArray = errors?.filter((item: any) => item.Type === 'Error' || item.Type === 'ToolTip');
  const warningsArray = errors?.filter((item: any) => item.Type === 'Warning');

  useEffect(() => {
    setOpenErrorAlert(open);
  }, [errors]);

  useEffect(() => {
    setOpenErrorAlert(open);
  }, [size]);

  useEffect(() => {
    setOpenErrorAlert(open);
  }, [open]);


  const hideWarningToasts = (targetIndex: any) => {
    // Select all elements whose IDs include the word "Warning"
    const elements = document.querySelectorAll('[id*=Warning]');

    // Iterate through the selected elements
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i] as HTMLElement; // Explicitly cast the element to HTMLElement
      // Extract the index from the element's ID
      const index = parseInt(element.id.split('-')[1]); // Assuming the ID format is "Warning-index"

      // Check if the index matches the target index
      if (index === targetIndex) {
        // Hide the element by setting its display property to 'none'
        element.style.display = 'none';
      }
    }
  }
  // useEffect(() => {
  //   setOpenErrorAlertWarning(open);
  // }, [errors]);

  // useEffect(() => {
  //   setOpenErrorAlertWarning(open);
  // }, [size]);

  // useEffect(() => {
  //   setOpenErrorAlertWarning(open);
  // }, [open]);


  return errors && openErrorAlert ? (
    <GelContainerLite>
      <CustomErrorAlert id="ErrorToast" style={{ gridGap: 5 }}>
        <>
          {errorsArray.map((error: any, index: any) => (
            <Toast key={index} style={{ backgroundColor: "#fce7e8" }}>
              <Icon>
                <svg
                  fill="none"
                  height="50"
                  viewBox="0 0 48 48"
                  width="50"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    d="m4 24c0-11.0457 8.9543-20 20-20s20 8.9543 20 20-8.9543 20-20 20-20-8.9543-20-20zm22-11c0-1.1046-.8954-2-2-2s-2 .8954-2 2v14c0 1.1046.8954 2 2 2s2-.8954 2-2zm-2 20c1.1046 0 2 .8954 2 2s-.8954 2-2 2-2-.8954-2-2 .8954-2 2-2z"
                    fill="red"
                    fillRule="evenodd"
                  />
                </svg>
              </Icon>
              <Content>
                <ContentParagraph>{error.Text}</ContentParagraph>
              </Content>
              <CloseIcon onClick={() => setOpenErrorAlert(!openErrorAlert)}>
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 32 32"
                  style={{ fill: "#383535" }}
                >
                  <defs>
                    <path
                      id="exit-small_svg__a"
                      d="M9.707 8.293L16 14.586l6.293-6.293a.999.999 0 111.414 1.414L17.414 16l6.293 6.293a.999.999 0 11-1.414 1.414L16 17.414l-6.293 6.293a.997.997 0 01-1.414 0 .999.999 0 010-1.414L14.586 16 8.293 9.707a.999.999 0 111.414-1.414z"
                    ></path>
                  </defs>
                  <use
                    fillRule="evenodd"
                    xlinkHref="#exit-small_svg__a"
                  ></use>
                </svg>
              </CloseIcon>
            </Toast>
          ))}
        </>
        <>
          {warningsArray.map((error: any, index: any) => (
            <Toast style={{ backgroundColor: "#fde9d2" }} id={`WarningToast-${index + 1}`}>
              <Icon>
<svg width="22px" height="22px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.4449 0.608765C8.0183 -0.107015 6.9817 -0.107015 6.55509 0.608766L0.161178 11.3368C-0.275824 12.07 0.252503 13 1.10608 13H13.8939C14.7475 13 15.2758 12.07 14.8388 11.3368L8.4449 0.608765ZM7.4141 1.12073C7.45288 1.05566 7.54712 1.05566 7.5859 1.12073L13.9798 11.8488C14.0196 11.9154 13.9715 12 13.8939 12H1.10608C1.02849 12 0.980454 11.9154 1.02018 11.8488L7.4141 1.12073ZM6.8269 4.48611C6.81221 4.10423 7.11783 3.78663 7.5 3.78663C7.88217 3.78663 8.18778 4.10423 8.1731 4.48612L8.01921 8.48701C8.00848 8.766 7.7792 8.98664 7.5 8.98664C7.2208 8.98664 6.99151 8.766 6.98078 8.48701L6.8269 4.48611ZM8.24989 10.476C8.24989 10.8902 7.9141 11.226 7.49989 11.226C7.08567 11.226 6.74989 10.8902 6.74989 10.476C6.74989 10.0618 7.08567 9.72599 7.49989 9.72599C7.9141 9.72599 8.24989 10.0618 8.24989 10.476Z"
    fill="#f89e34"
  />
</svg>
              </Icon>
              <Content>
                <ContentParagraph>{error.Text}</ContentParagraph>
              </Content>
              <CloseIcon onClick={() => hideWarningToasts(index + 1)}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 32 32"
                  style={{ fill: "#383535" }}
                >
                  <defs>
                    <path
                      id="exit-small_svg__a"
                      d="M9.707 8.293L16 14.586l6.293-6.293a.999.999 0 111.414 1.414L17.414 16l6.293 6.293a.999.999 0 11-1.414 1.414L16 17.414l-6.293 6.293a.997.997 0 01-1.414 0 .999.999 0 010-1.414L14.586 16 8.293 9.707a.999.999 0 111.414-1.414z"
                    ></path>
                  </defs>
                  <use fillRule="evenodd" xlinkHref="#exit-small_svg__a"></use>
                </svg>
              </CloseIcon>
            </Toast>
          ))}
        </>
      </CustomErrorAlert>
    </GelContainerLite>
  ) : (
    <></>
  );
};

export default ErrorHandler;
