import { connect } from "react-redux";
import { GelTabs, GelTab, GelButton } from "@tal-gel/components";
import LifeCardDetails from "./LifeCardDetails";
import CoverCard from "../CoverCard/CoverCard";

import styled, { css } from "styled-components";

const GelAddLifeInsuredButton = styled(GelButton)((props: any) => css``);

const ButtonSpan = styled.div`
  position: absolute;
  margin-bottom: 30px;
  z-index: 999;
  margin-top: -70px;
  margin-left: 75%;
  margin-right: 2%;
  @media (max-width: 1150px) {
    margin-left: 70%;
  }
`;

interface TabProps {
  policyData?: any;
  UpdateQuoteFn: any;
  inruleDataState?: any;
}

const LifeCard = ({ policyData, UpdateQuoteFn, inruleDataState }: TabProps) => {
  const lives = policyData?.Quote?.Lives?.filter(
    (life: any) => life.UIObjects !== null
  );

  return (
    <GelTabs id="life-card">
      {lives?.map((life: any, index: number) => {
        const lifeInsuredName = life?.UIObjects.find(
          (item: any) => item.Name === "LifeInsuredName"
        )?.Value;
        life.UIObjects = life.UIObjects.filter(
          (item: any) => item.IsHidden === false
        );
        if (life?.UIObjects != null && life?.UIObjects.length > 0) {
          return (
            <GelTab title={lifeInsuredName || "Life" + (index + 1)} key={index}>
              {/*  TODO uncomment this when Andrew/Jun want Add new life insured button to be shown
            <ButtonSpan>
              <GelAddLifeInsuredButton
                primary
                medium
                onClick={() => {}}
                disabled={false}
              >
                Add new Life Insured
              </GelAddLifeInsuredButton>
            </ButtonSpan> */}

              <LifeCardDetails
                id={`life-card-details-${index}`}
                life={life}
                UpdateQuoteFn={UpdateQuoteFn}
              />
              {life?.Covers?.filter(
                (item: any) =>
                  item?.UIObjects !== null && item?.UIObjects.length > 0
              ).map((cover: any, index: number) => {
                return (
                  <CoverCard
                    cover={cover}
                    key={index}
                    UpdateQuoteCallback={UpdateQuoteFn}
                    policyData={policyData}
                    inruleDataState={inruleDataState}
                  />
                );
              })}
            </GelTab>
          );
        }
      })}
    </GelTabs>
  );
};

export default connect()(LifeCard);
