import styled from "styled-components";

export const FooterTheme = styled.footer`
  // background-color: #222;
  // padding: 10px 5px;
  // text-align: center;
  // color: hsl(0, 0%, 100%);

  // a {
  //   color: hsl(0, 0%, 100%);
  // }
  // change footer based on different themes in future
`;
