import React, { useState } from "react";
import { GelContainerLite } from "@tal-gel/components";
import LandingPage from "./components/LandingPage/LandingPage";
import styled, { css } from "styled-components";
import ErrorHandler from "./components/ErrorHandler";
import CardMargin from "./components/shared/CardMargin";
import { GelToastContextProvider } from "@tal-gel/core";

const HomeContainerLite = styled(GelContainerLite)(
  (props) => css`
    @media (max-width: 991px) {
      display: none;
    }
    @media (min-width: 992px) {
      display: block;
    }
  `
);

const Home: React.FC = () => {
  const [error, setError] = useState([]);
  const errors = Object.keys(error || {});
  const errorsArray = errors?.filter(
    (item: any) => item.Type === "Error" || item.Type === "ToolTip"
  );
  function handleErrorToast(error: any) {
    setError(error);
  }

  return (
    <HomeContainerLite gutter={0}>
        {/* <ErrorHandler errors={error} /> */}
        {/* change this based on errorsArray and do not include warningsArray */}
        <CardMargin margin={`0 0 ${errors.length * 2}% 0px`} />
        <LandingPage errorHandlerCb={handleErrorToast} />
    </HomeContainerLite>
  );
};

export default Home;
