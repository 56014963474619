import { GelSpinner, GelHeading3 } from "@tal-gel/components";
import { DEFAULT_SPINNER_CHANGE } from "./shared/messages/Messages";

const LoaderComponent = (props: any) => {
  const { overlayDescription } = props;
  return (
    <GelSpinner
      large
      style={{ zIndex: 99999 }}
      overlay
      overlayDescription={
        props.overlayDescription ? (
          <GelHeading3 inverse>{overlayDescription}</GelHeading3>
        ) : (
          <GelHeading3 inverse>{DEFAULT_SPINNER_CHANGE}</GelHeading3>
        )
      }
    />
  );
};

export default LoaderComponent;
