import styled from "styled-components";

export const CardsContainerTheme = styled.section`
  margin: 50px;
`;

export const CardTheme = styled.div`
  background-color: hsl(60, 40%, 100%);
  border: 1px solid hsl(0, 0%, 87%);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  border-radius: 3px;
  max-width: 450px;
`;

export const CardTitleTheme = styled.div`
  font-weight: bold;
`;

export const CardBodyTheme = styled.div`
  color: black;
  background-color: ${({ theme }) => theme?.colors?.cardBody};
  padding: 10px;
`;

export const PoicyCardBodyTheme = styled(CardBodyTheme)`
  display: flex;
`;
