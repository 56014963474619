import { GelContainerLite } from "@tal-gel/components";
import { useState, useEffect } from "react";
import styled from "styled-components";

interface Error {
  errors?: any;
}

const Error = styled.span`
  font-family: "Pluto Sans Condensed", Arial, sans-serif;
  color: #e20f1a;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.125;
  letter-spacing: -0.25px;
  margin-top: 8px;
`;

const ValidationError = ({ errors }: Error) => {
  return <Error>{errors && errors[errors.length - 1]?.Text}</Error>;
};

export default ValidationError;
