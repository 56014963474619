import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GelToastContextProvider } from "@tal-gel/core";

// IMPORTANT! Add this before importing any GEL components
// and before importing any of your own components (eg: `App`)
import "./setupTalGel";

import { GelThemeProvider } from "@tal-gel/theming";
import { GelScreenDetector } from "@tal-gel/components";
import { BrowserRouter as Router } from "react-router-dom";
import config from "./OktaConfig";
import store from "./store/store";

// export const IndexRender = () => {
ReactDOM.render(
  <React.StrictMode>
    <GelToastContextProvider>
      <GelThemeProvider mode={process.env.NODE_ENV}>
        {" "}
        {/* Support tests */}
        <GelScreenDetector>
          <Router basename={config.app.basename}>
            <Provider store={store}>
              <App />
            </Provider>
          </Router>
        </GelScreenDetector>
      </GelThemeProvider>
    </GelToastContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// };

// IndexRender();
