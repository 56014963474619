import { combineReducers } from "redux";
import { policyReducer as policyResponse } from "./policy/reducer";
import { inRuleReducer as inruleResponse } from "./policy/reducer"; // move this to a different reducer if need be

const rootReducer = combineReducers({
  policyResponse,
  inruleResponse,
});

export default rootReducer;
