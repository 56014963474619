import * as _ from "lodash";

export const format = (value: any, formatting: any) => {
  // const regEx:any = "^\\$(\\d{1,3}(\\,\\d{3})*|(\\d+))(\\.\\d{2})?$";
  const regExp = formatting.replaceAll("\\", "");
  const currencySymbol = regExp[1];
  let sanitisedValue = value
    ?.replace(/[`${currencySymbol}`,a-zA-Z]/g, "")
    ?.replace(/^0/g, "");
  if (value.indexOf(".") !== -1 && value.split(".")[1]?.length > 2) {
    sanitisedValue = value?.slice(0, -1).replace(/[`${currencySymbol}`,]/g, "");
    // console.log(sanitisedValue, "sanitisedValue after deleting...");
  }
  // console.log(sanitisedValue, "sanitisedValue after removing $ , and .");
  return `${currencySymbol}${sanitisedValue
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
};

export const convertDropdownList = (options: any): any => {
  let optionsMap = [] as any;
  // console.log(options, "here dropdownlist");
  options?.map((item: any) => {
    if (item.hasOwnProperty("IsHidden")) {
      if (!item?.IsHidden) {
        optionsMap.push({ label: item.Value, value: item.Value });
      }
    } else {
      optionsMap.push({ label: item.Value, value: item.Value });
    }
  });
  return optionsMap;
};

export const getUrlParmValue = (name: any) => {
  const parmObj = (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
    window.location.search
  ) || [null, ""]) as any;
  return decodeURIComponent(parmObj[1]?.replace(/\+/g, "%20")) || null;
};

export const arrUnique = (arr: any) => {
  const cleaned: any = [];
  arr.forEach(function (itm: any) {
    var unique = true;
    cleaned.forEach(function (itm2: any) {
      if (JSON.stringify(itm) === JSON.stringify(itm2)) unique = false;
    });
    if (unique) cleaned.push(itm);
  });
  return cleaned;
};

/**
 * [Recursively loops through json object/array and returns values against all matched key]
 * @theObject      {[object]} [the json object or array to be searched into]
 * @key {[string]} [key to match in the json object]
 * @return      {[array]} [json array containing all json values matching the key]
 */
export const getObjectFindAll = (theObject: any, key: any, output: any) => {
  if (theObject instanceof Array) {
    for (var i = 0; i < theObject.length; i++) {
      for (var prop in theObject[i]) {
        if (prop == key) {
          output.push(theObject[i][prop]);
        }
      }

      output = getObjectFindAll(theObject[i], key, output);

      if (_.isArray(output) && output.length) {
        break;
      }
    }
  } else {
    for (var prop in theObject) {
      if (prop == key) {
        output.push(theObject[prop]);
      }
      if (
        theObject[prop] instanceof Object ||
        theObject[prop] instanceof Array
      ) {
        output = getObjectFindAll(theObject[prop], key, output);

        if (_.isArray(output) && output.length) {
          break;
        }
      }
    }
  }
  return output;
};
