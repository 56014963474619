import { getGelTokens } from "@tal-gel/theming";
import styled, { css } from "styled-components";

import {
  GelContainerLite,
  GelButton,
  GelRow,
  GelCol,
} from "@tal-gel/components";
import CoverHeader from "./CoverHeaderFooter";
import CoverDetails from "./CoverDetails";
import CoverCommission from "./CoverCommission";
import CardMargin from "../shared/CardMargin";
import { CardBodyTheme } from "../../themes/Cards.styled";

const CustomGelContainerLite = styled(GelContainerLite)(
  (props) => css`
    border: 1px solid gray;
    border-radius: 2px;
  `
);
interface CoverCardData {
  cover: any;
  UpdateQuoteCallback: any;
  policyData?: any;
  inruleDataState?: any;
}

const CoverCard = ({
  cover,
  UpdateQuoteCallback,
  policyData,
  inruleDataState,
}: CoverCardData) => {

  // cover.UIObjects = cover.UIObjects.filter(
  //   (item: any) => item.IsHidden === false
  // );

  const headerItems = cover.UIObjects.filter(
    (item: any) => item.FrameName === "CoverHeader"
  );
  // const uiObjectsAreNotHidden = cover.UIObjects.filter(
  //   (item: any) => item.IsHidden === false
  // );

  const footerItems = cover.UIObjects.filter(
    (item: any) => item.FrameName === "CoverFooter"
  );

  const commissionItems = cover.UIObjects.filter(
    (item: any) =>
      item.FrameName === "CoverCommission" ||
      item.FrameName === "CoverCommissionHeader"
  ).sort(
    (a: any, b: any) =>
      a.LabelGridCell.substr(a.LabelGridCell.length - 1) -
      b.LabelGridCell.substr(b.LabelGridCell.length - 1)
  );

  const commissionDiscountItems = cover.UIObjects.filter(
    (item: any) =>
      item.FrameName === "CoverDiscounts" ||
      item.FrameName === "CoverDiscountHeader"
  ).sort(
    (a: any, b: any) =>
      a.LabelGridCell.substr(a.LabelGridCell.length - 1) -
      b.LabelGridCell.substr(b.LabelGridCell.length - 1)
  );

  const list = cover.UIObjects.filter(
    (item: any) =>
      item.FrameName === "CoverDetails" ||
      item.FrameName == "CoverSettings" ||
      item.FrameName == "CoverOptions"
  );

  return (
    <CustomGelContainerLite
      id={`cover-card-${cover.Id}-${cover?.CoverType}-${cover?.LinkedCoverType}`}
      style={{ marginBottom: "15px" }}
    >
      <CoverHeader
        id={`cover-card-header-${cover.Id}-${cover?.CoverType}-${cover?.LinkedCoverType}`}
        cover={cover}
        UIObjects={headerItems}
        UpdateQuote={UpdateQuoteCallback}
        backgroundColor={getGelTokens().global.themeColorBackgroundInverse}
        fontColor="white"
      />
      <CardBodyTheme>
        <CoverDetails
          cover={cover}
          UIObjects={list}
          UpdateQuoteCallback={UpdateQuoteCallback}
          policyData={policyData}
          inruleDataState={inruleDataState}
        />

        <CoverCommission
          id={`cover-commission-${cover.Id}-${cover?.CoverType}-${cover?.LinkedCoverType}`}
          cover={cover}
          UIObjects={commissionItems}
          UpdateQuoteCallback={UpdateQuoteCallback}
        />

        {commissionDiscountItems.length > 0 && (
          <>
            <CardMargin margin={"20px 0px 20px 0px"} />
            <CoverCommission
              id={`cover-commission-${cover.Id}-${cover?.CoverType}-${cover?.LinkedCoverType}`}
              cover={cover}
              UIObjects={commissionDiscountItems}
              UpdateQuoteCallback={UpdateQuoteCallback}
            />
          </>
        )}
      </CardBodyTheme>

      <CardMargin margin={"0 0 10px 0"} />
      <CoverHeader
        id={`cover-card-footer-${cover.Id}-${cover?.CoverType}-${cover?.LinkedCoverType}`}
        cover={cover}
        UIObjects={footerItems}
        UpdateQuote={UpdateQuoteCallback}
        backgroundColor={getGelTokens().global.themeColorGrayT20}
      />
    </CustomGelContainerLite>
  );
};
export default CoverCard;
