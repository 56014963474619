import {} from "../../models/memberDetails";
export const GET_POLICY_DETAILS = "@@TAL/GET_POLICY_DETAILS";
export const GET_POLICY_DETAILS_SUCCESS = "@@TAL/GET_POLICY_DETAILS_SUCCESS";
export const GET_POLICY_DETAILS_ERROR = "@@TAL/GET_POLICY_DETAILS_ERROR";

export const GET_AssociatedPolicy_DETAILS =
  "@@TAL/GET_AssociatedPolicy_DETAILS";
export const GET_AssociatedPolicy_DETAILS_SUCCESS =
  "@@TAL/GET_AssociatedPolicy_DETAILS_SUCCESS";
export const GET_AssociatedPolicy_DETAILS_ERROR =
  "@@TAL/GET_AssociatedPolicy_DETAILS_ERROR";

export const UPDATE_QUOTE = "@@TAL/UPDATE_QUOTE";
export const UPDATE_QUOTE_SUCCESS = "@@TAL/UPDATE_QUOTE_SUCCESS";
export const UPDATE_QUOTE_ERROR = "@@TAL/UPDATE_QUOTE_ERROR";

export const INITIALIZE_INRULE_ENGINE = "@@TAL/INITIALIZE_INRULE_ENGINE";

export const TRIGGER_INRULE_VALIDATION = "@@TAL/TRIGGER_INRULE_VALIDATION";

export const initializeInruleEngine = (data: any) => {
  return {
    type: INITIALIZE_INRULE_ENGINE,
    state: "INITIALIZE_INRULE_ENGINE",
    response: data,
  };
};

export const triggerInruleValidation = (data: any) => {
  return {
    type: TRIGGER_INRULE_VALIDATION,
    state: "TRIGGER_INRULE_VALIDATION",
    response: data,
  };
};

export const fetchPolicyDetails = (data: any) => {
  return {
    type: GET_POLICY_DETAILS,
    state: "GET_POLICY_DETAILS",
    response: data,
  };
};

export const fetchPolicyDetailsSuccess = (data: any) => {
  return {
    type: GET_POLICY_DETAILS_SUCCESS,
    state: "GET_POLICY_DETAILS_SUCCESS",
    response: data,
  };
};

export const fetchPolicyDetailsError = (data: any) => {
  return {
    type: GET_POLICY_DETAILS_ERROR,
    state: "GET_POLICY_DETAILS_ERROR",
    response: { error: data, success: false },
  };
};

export const fetchAssociatedPolicyDetails = (data: any) => {
  return {
    type: GET_AssociatedPolicy_DETAILS,
    state: "GET_AssociatedPolicy_DETAILS",
    response: data,
  };
};

export const fetchAssociatedPolicyDetailsSuccess = (data: any) => {
  return {
    type: GET_AssociatedPolicy_DETAILS_SUCCESS,
    state: "GET_AssociatedPolicy_DETAILS_SUCCESS",
    response: data,
  };
};

export const fetchAssociatedPolicyDetailsError = (data: any) => {
  return {
    type: GET_AssociatedPolicy_DETAILS_ERROR,
    state: "GET_AssociatedPolicy_DETAILS_ERROR",
    response: { error: data, success: false },
  };
};

export const fetchUpdatedQuote = (
  data: any,
  isUpdateQuoteModel: boolean = false
) => {
  // DGOTO 3970 added condition to differntiate add cove
  let updatedData = {
    ...data,
    isUpdateQuoteModel: isUpdateQuoteModel,
  };
  return {
    type: UPDATE_QUOTE,
    state: "UPDATE_QUOTE",
    response: updatedData,
    isUpdateQuoteModel: isUpdateQuoteModel,
  };
};

export const fetchUpdatedQuoteSuccess = (
  data: any,
  isUpdateQuoteModel: boolean = false
) => {
  // DGOTO 3970 added condition to differntiate add cove
  let updatedData = {
    ...data,
    isUpdateQuoteModel: isUpdateQuoteModel,
  };
  return {
    type: UPDATE_QUOTE_SUCCESS,
    state: "UPDATE_QUOTE_SUCCESS",
    response: updatedData,
    isUpdateQuoteModel: isUpdateQuoteModel,
  };
};

export const fetchUpdatedQuoteError = (
  data: any,
  isUpdateQuoteModel: boolean = false
) => {
  return {
    type: UPDATE_QUOTE_ERROR,
    state: "UPDATE_QUOTE_ERROR",
    response: { error: data, success: false },
  };
};
