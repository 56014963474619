import { GelContainerLite, GelRowLayout } from "@tal-gel/components";

import CardMargin from "../shared/CardMargin";
import QuoteDetails from "./QuoteDetails";
import PolicyCard from "../PolicyCard/PolicyCard";
import LifeCard from "../LifeCard/LifeCard";
import { connect } from "react-redux";
import { fetchUpdatedQuote } from "../../store/policy/actions";
import { applyQuoteLifeCoverModelRules,applyRulesWrapper } from "../../services/inRuleService";
import { getObjectFindAll } from "../../utilities/utils";
import { useEffect, useState } from "react";

/* TODO this block is for debugging purpose to be removed later after min js is 100% fixed */
const validateIfNoErrors = (
  quoteUIObjects: any,
  policyUIObjects: any,
  lifeUIObjects: any,
  coversUIObjects: any
) => {
  if (process.env?.REACT_APP_ENV_Stage === "true") {
    console.log(
      quoteUIObjects,
      policyUIObjects,
      lifeUIObjects,
      coversUIObjects,
      "all UIObjects"
    );
  }

  const quoteVal = iterateUIObjects(quoteUIObjects);
  if (quoteVal === false) return false;

  for (let i = 0; i < policyUIObjects.length; i++) {
    let policyVal = iterateUIObjects(policyUIObjects[i]);
    if (policyVal === false) return false;
  }

  for (let j = 0; j < lifeUIObjects.length; j++) {
    const lifeVal = iterateUIObjects(lifeUIObjects[j]);
    if (lifeVal === false) return false;
  }

  for (let k = 0; k < coversUIObjects?.length; k++) {
    const coversVal = iterateUIObjects(coversUIObjects[k], "covers");
    if (coversVal === false) return false;
  }
  return true;
};

const iterateUIObjects = (obj: any, type: string = "") => {
  const item = type === "covers" ? obj : obj[0];
  for (let i = 0; i < item?.length; i++) {
    if (process.env?.REACT_APP_ENV_Stage === "true") {
      console.log(item[i]?.Message, item[i], "Message");
    }
    const msgArray = item[i]?.Message;
    if (msgArray !== null) {
      for (let j = 0; j < msgArray?.length; j++) {
        if (msgArray[j].Type === "Error") {
          return false;
        }
      }
    }
  }

  return true;
};
/* TODO this block is for debugging purpose to be removed later after min js is 100% fixed */

const QuoteCard = (policyResponse: any) => {
  const [inruleCalled, setInRuleCalled] = useState(false);
  const [inruleDataState, setInruleDataState] = useState(null);
  const [jsonData, setJsonData] = useState<any>(null);
  const sampleJSONData = jsonData;

 // Force To load minjs!
//  useEffect(() => {
//   const timeoutId = setTimeout(() => {
//     let inruleData = { ...policyResponse?.policyResponse?.data };
//     applyQuoteLifeCoverModelRules(inruleData);
//   }, 3000); 
//   return () => clearTimeout(timeoutId);
// }, [policyResponse]);
 //
// applyRulesWrapper("QuoteModel", policyResponse?.policyResponse?.data);

  useEffect(() => {
    console.log("refresh page after inrule update...");
  }, [inruleCalled]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const fileName = new URL(window.location.href).searchParams.get("payLoad");
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.PUBLIC_URL}/automationJTestSONs/${fileName}`
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setJsonData(data);
      } catch (error) {
        console.error("Error fetching JSON file:", error);
      }
    };

    fetchData();
  }, []);

  let isUpdateQuoteCardModel = false; //DGOTO- 3970 - Add Cover

  let responseQuote = policyResponse?.policyResponse?.data?.Quote;
  //let filteredResponseQuote = responseQuote ? [...responseQuote.UIObjects].filter(item => !item.IsHidden) : [];

  // if(responseQuote != null) {
  //   responseQuote.UIObjects = responseQuote.UIObjects.filter(
  //     (item: any) => item.IsHidden === false
  //   );
  // }
  let responseData = policyResponse?.policyResponse?.data;
  let responsePolicies = policyResponse?.policyResponse?.data?.Quote?.Policies;
  //   let filteredResponsePolicies = responsePolicies ? [...responsePolicies].map(policy => {
  //     if (policy.UIObjects != null) {
  //         return {
  //             ...policy,
  //             UIObjects: policy.UIObjects.filter((item:any)=> !item.IsHidden)
  //         };
  //     }
  //     return policy;
  // }) : [];
  // if(responsePolicies != null){
  //   responsePolicies.forEach((policy: any) => {
  //      if(policy.UIObjects != null){
  //     policy.UIObjects = policy.UIObjects.filter(
  //       (item: any) => item.IsHidden === false
  //     );
  //   }
  //   });
  // }
  if (Object.keys(policyResponse?.policyResponse).length === 0) {
    responseQuote = sampleJSONData?.Quote;
    responseData = sampleJSONData;
    responsePolicies = sampleJSONData?.Quote?.Policies;
  }

  const handleInruleError = () => {
    // setErrors([{ Text: "Please review Quote details", Code: "Inrule" }]);
    // policyResponse.errorHandlerCb([
    //   { Text: "Please review Quote details", Code: "Inrule" },
    // ]);
    // TODO keeping this handler for now as we may want to use it in future to do some custom actions on inrule error
  };

  function UpdateQuote(updateQuoteModel: string) {
    let inruleData = null;
    if (policyResponse?.policyResponse?.data != undefined) {
      inruleData = { ...policyResponse?.policyResponse?.data };
    } else inruleData = responseData;

    if (process.env?.REACT_APP_ENV_Stage === "true") {
      console.log(
        inruleData,
        "this is the data before sending to FE inrule",
        policyResponse?.policyResponse?.data
      );
    }
    /* TODO this block is for debugging purpose to be removed later after min js is 100% fixed */
    const quoteUIObjects: any = [];
    const lifeUIObjects: any = new Array(inruleData?.Quote?.Lives?.length);
    const policyUIObjects: any = new Array(inruleData?.Quote?.Policies?.length);
    let coversUIObjects;
    applyQuoteLifeCoverModelRules(inruleData);
    setInRuleCalled(true);
    setInruleDataState(inruleData);

    if (process.env?.REACT_APP_ENV_Stage === "true") {
      console.log(
        inruleData,
        "inrule response",
        JSON.stringify(inruleData) ===
          JSON.stringify(policyResponse?.policyResponse?.data)
      );
    }
    getObjectFindAll(inruleData?.Quote, "UIObjects", quoteUIObjects);
    for (let i = 0; i < policyUIObjects?.length; i++) {
      policyUIObjects[i] = [];
      getObjectFindAll(
        inruleData?.Quote?.Policies,
        "UIObjects",
        policyUIObjects[i]
      );
    }

    for (let j = 0; j < lifeUIObjects?.length; j++) {
      lifeUIObjects[j] = [];
      getObjectFindAll(inruleData?.Quote?.Lives, "UIObjects", lifeUIObjects[j]);
      coversUIObjects = new Array(inruleData?.Quote?.Lives[j]?.Covers?.length);
      for (let k = 0; k < inruleData?.Quote?.Lives[j]?.Covers?.length; k++) {
        coversUIObjects[k] = inruleData?.Quote?.Lives[j]?.Covers[k]?.UIObjects;
      }
      if (process.env?.REACT_APP_ENV_Stage === "true") {
        console.log(
          "coversUIObjects",
          inruleData?.Quote?.Lives[j]?.Covers,
          coversUIObjects
        );
      }
    }

    validateIfNoErrors(
      quoteUIObjects,
      policyUIObjects,
      lifeUIObjects,
      coversUIObjects
    );
    /* TODO this block is for debugging purpose to be removed later after min js is 100% fixed */
    let formHasErrors = false;
    const indicatorFlags = inruleData?.IndicatorFlags;
    for (let i = 0; i < indicatorFlags.length; i++) {
      if (indicatorFlags[i]?.Name === "HasErrorUI") {
        formHasErrors = indicatorFlags[i]?.Value;
        break;
      }
    }
    if (process.env?.REACT_APP_ENV_Stage === "true") {
      console.log("HasErrorUI = ", formHasErrors);
    }

    if (!formHasErrors) {
      if(updateQuoteModel == undefined || updateQuoteModel === undefined || updateQuoteModel == "undefined" || updateQuoteModel == ""){
        policyResponse.UpdateQuote(responseData);
      }
      else{
        if (updateQuoteModel.toString().toLowerCase() === "update_quote_model") {
          isUpdateQuoteCardModel = true; //DGOTO- 3970 - Add Cover
          policyResponse.UpdateQuote(responseData, isUpdateQuoteCardModel); ////DGOTO- 3970 - Add Cover pass to UpdateCode to differenciate between add cover
        } 
      }
     
      // else {
      //   policyResponse.UpdateQuote(responseData);
      // }
    } else {
      //updateInruleErrorCb is undefined
      if (policyResponse.updateInruleErrorCb() != undefined) {
        policyResponse.updateInruleErrorCb();
      } else {
        handleInruleError();
      }
    }
  }
  return (
    <GelContainerLite id="quote-card" fixed>
      {responseQuote ? (
        <QuoteDetails quote={responseQuote} inruleDataState={inruleDataState} />
      ) : (
        <></>
      )}
      {responsePolicies
        ?.filter(
          (policy: any) =>
            policy?.UIObjects != null && policy?.UIObjects.length !== 0
        )
        .map((item: any, index: number) => {
          return (
            <PolicyCard
              key={index}
              policy={item}
              UpdatedQuoteFn={UpdateQuote}
            />
          );
        })}
      <CardMargin margin={"20px 0px 20px 0px"} />
      {responseData ? (
        <LifeCard
          policyData={responseData}
          inruleDataState={inruleDataState}
          UpdateQuoteFn={UpdateQuote}
        />
      ) : (
        <></>
      )}
    </GelContainerLite>
  );
};

const mapStateToProps = (state: any) => ({
  policyResponse: state.policyResponse,
});
const mapDispatchToProps = (dispatch: any) => {
  return {
    //DGOTO- 3970 - Add Cover -Added isUpdateQuoteModel flag
    UpdateQuote: (policyResponse: any, isUpdateQuoteModel: boolean = false) =>
      dispatch(fetchUpdatedQuote(policyResponse, isUpdateQuoteModel)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(QuoteCard);
