import {
  GelPageLayout,
  GelPageHeader,
  GelPageSideNav,
  GelPageContent,
  GelPageFooter,
  GelButton,
  GelToastsContainer,
  GelToast,
  GelContainerLite,
  GelForm,
  useGelFormData,
  
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import QuoteCard from "../QuoteCard/QuoteCard";
import LoaderComponent from "../Loader";
import { connect } from "react-redux";
import { getQuoteIllustrationDocument } from "../../services/policyService";
import Header from "./Header";
import Footer from "./Footer";
import SideNav from "./SideNav";
import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { HeaderTheme } from "../../themes/Header.styled";
import { FooterTheme } from "../../themes/Footer.styled";
import Draggable from "react-draggable";
import { reduce } from "lodash";
import QuoteDownloadModal from "./QuoteDownloadModal";
import { DOWNLOAD_QUOTE_ILLUSTRATION } from "../shared/messages/Messages";
import ErrorHandler from "../ErrorHandler";
import { Resizable } from 'react-resizable';


let inRuleScriptFound = false;

const LandingPage = (policyResponse: any) => {
  const [errors, setErrors] = useState(
    policyResponse?.policyResponse?.data?.Messages
  );
  //Clear Cache
  if (localStorage.getItem("okta-cache-storage") !== null) {
    localStorage.removeItem("okta-cache-storage");
  }
  const [isLoading, setIsLoading] = useState(false);
  const [isAdviserHide, setisAdviserHide] = useState("false");
  const [openModal, setOpenModal] = useState(false);
  const [shouldAdviserSwitchBeShown, setShouldAdviserSwitchBeShown] = useState(true);
  const [isDragAreaMouseDown, setIsDragAreaMouseDown] = useState(false);
  const [inSideBarOGPosition, setInSideBarOGPosition] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 256, height: 0 });
  const [EBQLogoHeaderHeight, setEBQLogoHeaderHeight] = useState(0);

  type ResizeCallbackData = {
    node: HTMLElement;
    size: { width: number; height: number };
    handle: 's' | 'w' | 'e' | 'n' | 'sw' | 'se' | 'nw' | 'ne';
  };
  const onResize = (event: React.SyntheticEvent, data: ResizeCallbackData) => {
    setDimensions({
      width: data.size.width,
      height: data.size.height,
    });
  };

  const resetResize = () => {
    if (targetRef.current) {
      setDimensions({width: 256, height: targetRef.current.offsetHeight}) 
    }       
    if (boxRef.current) {
      boxRef.current.style.transform = 'translate(0px, 0px)';
    }
  };
  const targetRef = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);

  const checkOverlap = () => {
    if (boxRef.current && targetRef.current) {
      const boxRect = boxRef.current.getBoundingClientRect();
      const targetRect = targetRef.current.getBoundingClientRect();

      const overlap = !(
        boxRect.right < targetRect.left ||
        boxRect.left > targetRect.right ||
        boxRect.bottom < targetRect.top ||
        boxRect.top > targetRect.bottom
      );
      setInSideBarOGPosition(overlap);
    }
  };
  useEffect(() => {
    document.addEventListener('mousemove', checkOverlap);
    return () => {
      document.removeEventListener('mousemove', checkOverlap);
    };
  }, []);

  useEffect(() => {
    let flag = false; // Initialize the flag

policyResponse?.policyResponse?.data?.Quote?.Lives.forEach(function (life: any) {
  life.UIObjects?.forEach(function (obj: any) {
    if (
      obj.ObjectType === "Button" &&
      obj.Name.startsWith("AddCover_") &&
      obj.IsChanged === true
    ) {
      flag = true; // Set the flag if the condition is met
    }
  });
});
    // Check if quoteData exists and is not empty
    if (policyResponse?.policyResponse?.data?.Quote?.Lives) {
      // Iterate over the lives array
      policyResponse?.policyResponse?.data?.Quote.Lives.forEach((life: any, lifeIndex: any) => {
        // Iterate over the covers array within each life
        life.Covers.forEach((cover: any, coverIndex: any) => {
          if (cover.RequestAction === 'Add' && flag) {
           const coverTobeScrolled = document.getElementById(`cover-card-${cover.Id}-${cover.CoverType}-${cover.LinkedCoverType}`);
           coverTobeScrolled?.scrollIntoView({ behavior: 'smooth' });
          }
        });
      });
    }
  }, [policyResponse?.policyResponse?.data]);
  
  
  const handleMouseLeave = () => {
    setInSideBarOGPosition(false); // Set back to false when mouse leaves the div
  };

  const returnToOGPosition = () => {
    // Delay before snapping back to original position
    setTimeout(() => {
      // Check conditions for snapping back
      if (isDragAreaMouseDown && inSideBarOGPosition && !isResizing && boxRef.current) {
        // Snap back to original position
        resetResize()
      } else {
      }
    }, 50); // Adjust delay as needed
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const EBQLogo = document.getElementById("EBQLogoHeader");
      if (EBQLogo) {
        const rect = EBQLogo.getBoundingClientRect(); // Get the bounding rectangle of the element
        const distanceToTop = rect.top; // Calculate the distance from the top of the viewport to the top of the element
        setEBQLogoHeaderHeight(distanceToTop);
        console.log("Distance to top of window:", distanceToTop, "pixels");
        if (targetRef.current && dimensions.height === 0) {
          console.log("Distance to bottom of window:", targetRef.current.offsetHeight, "pixels")
          setDimensions({width: 256, height: targetRef.current.offsetHeight}) 
        }
      } else {
        console.error("Element not found!");
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [ ,errors]);

  if (policyResponse.hasOwnProperty("errorHandlerCb")) {
    policyResponse.errorHandlerCb(errors);
  }

  // const { formData, onFormDataChange, onFormDataReset, resetForm } =
  //   useGelFormData({
  //     textInput: "",
  //   });
  useEffect(() => {
    setErrors(policyResponse?.policyResponse?.data?.Messages);

    const productCode = policyResponse?.policyResponse?.data?.ProductCode;
    const productSeries = policyResponse?.policyResponse?.data?.ProductSeries;
    let seriesVersion = policyResponse?.policyResponse?.data?.SeriesVersion;

    //Setting default value for series veRsion
    productSeries < 37 ? (seriesVersion = 1) : (seriesVersion = seriesVersion);

    if (productCode && productSeries && seriesVersion) {
      const inRuleScriptPath = [
        productCode,
        parseInt(productSeries).toString(),
        seriesVersion,
        "ProductRules.min.js",
      ].join("_");

      const script = document.createElement("script");

      script.src = "";
      const eBQAppUrl = document.getElementById("ebqAppUrl");
      if (eBQAppUrl) {
        const ebqURL: HTMLElement | null = document.getElementById("ebqAppUrl");
        script.src = ebqURL?.innerHTML + "/js/inrule/" + inRuleScriptPath;
      } else {
        script.src = "/js/inrule/" + inRuleScriptPath;
      }

      script.async = true;
      const scripts = document.getElementsByTagName("script");
      for (let i = 0; i < scripts.length; i++) {
        if (scripts[i]?.src.indexOf("ProductRules.min.js") !== -1) {
          inRuleScriptFound = true;
          break;
        } else {
          document.body.appendChild(script);
        }
      }
      if (!inRuleScriptFound) {
        ruleBookExists(script.src).then(async (res) => res);
        document.body.appendChild(script);
      } else {
        // logic to replace existing inrule script
        const scripts = document.getElementsByTagName("script");
        for (let j = 0; j < scripts.length; j++) {
          const scriptSrc = scripts[j]?.src;
          const scriptsArr = scriptSrc.split("/");
          if (
            scriptSrc.indexOf("ProductRules.min.js") !== -1 &&
            scriptsArr[scriptsArr.length - 1] !== inRuleScriptPath
          ) {
            if (process.env?.REACT_APP_ENV_Stage === "true") {
              console.log(
                scripts[j]?.src,
                inRuleScriptPath,
                "scripts testing",
                "Stage",
                process.env?.REACT_APP_ENV_Stage
              );
            }
            scripts[j]?.parentNode?.removeChild(scripts[j]);
            const script = document.createElement("script");

            script.src = "/js/inrule/" + inRuleScriptPath;
            script.async = true;
            ruleBookExists(script.src).then(async (res) => res);
            document.body.appendChild(script);
            break;
          }
        }
      }
    }

    //Quote UIObjects
    policyResponse?.policyResponse?.data?.Quote?.UIObjects.forEach(function (
      obj: any
    ) {
      obj.IsChanged = false;
    });

    //Policy UIObjects
    let policies = policyResponse?.policyResponse?.data?.Quote?.Policies;
    policies?.forEach(function (policy: any) {
      policy?.UIObjects?.forEach(function (uiobject: any) {
        uiobject.IsChanged = false;
      });

      //Live UIObjects
      let lives = policyResponse?.policyResponse?.data?.Quote?.Lives;
      lives?.forEach(function (live: any) {
        live?.UIObjects?.forEach(function (uiobject: any) {
          uiobject.IsChanged = false;
        });
      });

      //Cover UIObjects
      let liveCovers: any = [];
      lives?.forEach(function (live: any) {
        live?.Covers?.forEach(function (cover: any) {
          liveCovers.push(cover);
        });
      });

      liveCovers?.forEach(function (cover: any) {
        cover?.UIObjects?.forEach(function (uiobject: any) {
          uiobject.IsChanged = false;
        });
      });
    });
  }, [policyResponse?.policyResponse]);

  async function ruleBookExists(url: string): Promise<any> {
    try {
      const response = await fetch(url, { method: "HEAD" });

      if (response.status === 404) {
        setErrors([
          { Text: "Rulebook doesn't exist for this series!!", Code: "Inrule" },
        ]);
      }
    } catch (e) {
      if (process.env?.REACT_APP_ENV_Stage === "true") {
        console.error("Can't fetch the rulebook min.js status=404");
      }
    }
  }

  const quoteIllustrationBtnDisabled = policyResponse?.policyResponse?.errors
    ? Object.values(policyResponse?.policyResponse?.errors)?.filter(
        (item: any) => item.Type === "Error"
      ).length > 0
    : false;

  const getQuoteIllustration = (isAdviserHide: string, isBenefitProjection: boolean = false , isPremiumProjection: boolean = false, projectionDuration: number ) => {
    if (quoteIllustrationBtnDisabled === true) return;
    setIsLoading(true);
    setErrors([]);
    getQuoteIllustrationDocument(
      policyResponse?.policyResponse?.data,
      isAdviserHide, 
      isBenefitProjection, 
      isPremiumProjection, 
      projectionDuration
    )
      .then((result: any) => {
        setIsLoading(false);
      })
      .catch((error: any) => {
        setErrors([{ Text: error.message, Code: "QuoteIllustration" }]);
        setIsLoading(false);
      });
  };

  const callToggleSwitchHandler = (data: any) => {
    // console.log(`switch: ${data}`)
    setisAdviserHide(data);
  };

  /* Right now not using Gelform hence keeping it commented. In future when there are code enhancements, we may uncomment this
  const onSubmit = () => {
    console.log("Submitting form data: ");
    // In your application, you may use
    // `fetch` or `axios` to call the real api
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        resolve({ data: "data" });
        //  reject({ message: 'error' });
        clearTimeout(timer);
      }, 1000);
    });
  };

  const onResponse = (response: any) => {
    console.log("response", response);
  };

  const onError = (error: any) => {
    console.error("error", error);
  };*/

  const handleInruleError = () => {
    // setErrors([{ Text: "Please review Quote details", Code: "Inrule" }]);
    // policyResponse.errorHandlerCb([
    //   { Text: "Please review Quote details", Code: "Inrule" },
    // ]);
    // TODO keeping this handler for now as we may want to use it in future to do some custom actions on inrule error
  };

  // Replicate GelPageSideNav
  const resizableStyle: React.CSSProperties = {
    background: getGelTokens().global.themeColorGrayT10,
    // position: "fixed",
    top: 50,
    left: -20,
    marginTop: `${EBQLogoHeaderHeight}px`,
    zIndex: 200,
    transition: "box-shadow 0.5s ease",
    boxShadow: isDragAreaMouseDown ? "0px 0px 10px rgba(0, 0, 0, 0.3)" : "none",
    padding: "3px",
    paddingTop: "8px",
    flexDirection: "column",
    boxSizing: "border-box",
    marginBottom: "10px",
    overflow: "hidden",
    margin: "20px",
    flexGrow: 1
  };

  const HandleStyle: React.CSSProperties = {
    position: "absolute",
    width: "8px",
    height: "8px",
    bottom: "10px",
    right: "10px",
    cursor: "se-resize",
    margin: 3,
    padding: 5
  }

  const targetStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    padding: "2px",
    backgroundColor:
      isDragAreaMouseDown
        ? inSideBarOGPosition
          ? "#cce7b3"
          : "#e6f3d9"
        : getGelTokens().global.themeColorGrayT05,
    transition: "background-color 0.3s ease, border-color 0.3s ease",
    border: "3px solid transparent",
    borderColor: isDragAreaMouseDown ? "#80c342" : "transparent",
  };

  return (
    <GelPageLayout>
      <GelPageHeader>
        <HeaderTheme>
          <Header />
        </HeaderTheme>
      </GelPageHeader>
        {/* <div
          ref={targetRef}
          onMouseLeave={handleMouseLeave}
          style={targetStyle}
          
        ></div> */}
        <GelPageSideNav
        sliding
        style={{
          backgroundColor: getGelTokens().global.themeColorGrayT10,
        }}
      >
        <SideNav
          policyData={policyResponse?.policyResponse?.data}
          getQuoteIllustrationDocument={getQuoteIllustration}
          quoteIllustrationBtnDisabled={quoteIllustrationBtnDisabled}
          isLoading={isLoading}
        />
      </GelPageSideNav>
      <GelPageContent
        style={{
          backgroundColor: getGelTokens().global.themeColorGrayT05,
        }}
      >
        {isLoading && (
          <LoaderComponent overlayDescription={DOWNLOAD_QUOTE_ILLUSTRATION} />
        )}
        {policyResponse?.policyResponse?.isLoading && <LoaderComponent />}
        {policyResponse?.policyResponse?.data?.Quote && (
          <QuoteCard updateInruleErrorCb={handleInruleError} />
        )}
        {/* DISABLE FOR RELEASE Draggable and resizable sidenav bar */}
        {/* <Draggable handle="strong">
          <Resizable 
            height={dimensions.height}
            width={dimensions.width}
            onResize={onResize}
            style={resizableStyle}
            minConstraints={[160, 350]}
            DISABLE FOR RELEASE
            handle={<div style={HandleStyle}>
                 <svg version="1.0" xmlns="http://www.w3.org/2000/svg"  width="10pt" height="10pt" viewBox="0 0 256.000000 256.000000"  preserveAspectRatio="xMidYMid meet">  <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)" fill="#B4B4B4" stroke="none"> <path d="M2323 2539 c-24 -7 -323 -300 -1161 -1138 -1182 -1182 -1152 -1150 -1152 -1232 0 -38 38 -103 74 -127 46 -31 91 -40 146 -29 42 9 102 67 1176 1141 1072 1072 1133 1135 1141 1177 29 141 -87 248 -224 208z"/> <path d="M2314 1506 c-43 -19 -1243 -1220 -1260 -1262 -41 -98 19 -209 125 -233 46 -10 87 -2 130 25 47 31 1208 1198 1226 1234 73 139 -77 298 -221 236z"/> <path d="M2315 511 c-23 -11 -88 -67 -151 -132 -127 -129 -147 -167 -125 -239 32 -108 145 -162 243 -116 20 10 85 68 145 129 91 93 111 120 121 157 15 62 0 119 -46 164 -55 56 -119 68 -187 37z"/> </g> </svg>
              </div>
            }
            onPointerUp={returnToOGPosition}
          >
            <div           
              ref={boxRef}
              className="box"
              style={{
                width: `${dimensions.width}px`,
                height: `${dimensions.height}px`,
              }}
            >
              <div>
                width: {dimensions.width}
                height: {dimensions.height}

              </div>
              <SideNav
                  policyData={policyResponse?.policyResponse?.data}
                  getQuoteIllustrationDocument={getQuoteIllustration}
                  quoteIllustrationBtnDisabled={quoteIllustrationBtnDisabled}
                  isLoading={isLoading}
                  // DISABLE FOR RELEASE
                  // setisAdviserHide={setisAdviserHide}
                  // isAdviserHide={isAdviserHide}
                  // openModal={openModal}
                  // setOpenModal={setOpenModal}
                  // setIsDragAreaMouseDown={setIsDragAreaMouseDown}
                  // setShouldAdviserSwitchBeShown={setShouldAdviserSwitchBeShown}
                />
            </div>
          </Resizable>
        </Draggable> */}
        {/* DISABLE FOR RELEASE
        <QuoteDownloadModal
          getQuoteIllustrationDocument={getQuoteIllustration}
          isAdviserHide={isAdviserHide}
          openModal={openModal}
          setOpenModal={setOpenModal}
          shouldAdviserSwitchBeShown={shouldAdviserSwitchBeShown}
          callToggleSwitchHandler={callToggleSwitchHandler}
        /> */}
        <ErrorHandler errors={errors}></ErrorHandler>
      </GelPageContent>

      <GelPageFooter>
        {process.env.REACT_APP_ThemeEnabled === "true" ? (
          <FooterTheme>
            <Footer />
          </FooterTheme>
        ) : (
          <Footer />
        )}
      </GelPageFooter>
    </GelPageLayout>
  );
};

const mapStateToProps = (state: any) => ({
  policyResponse: state.policyResponse,
});

export default connect(mapStateToProps)(LandingPage);
