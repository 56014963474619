import {
  GelTextInput,
  GelCheckbox,
  GelButton,
  GelRadio,
  GelRadioGroup,
  GelSelect,
  GelForm,
  GelCheckboxGroup,
  GelLabel,
  GelParagraph,
  GelStandardDateInput,
  GelTooltip,
} from "@tal-gel/components";

import { convertDropdownList } from "../../utilities/utils";

import "../PolicyCardContent.css";
import { useEffect, useRef, useState } from "react";
import { mapRegExp, validateRegExp } from "../../validations/validator";
import ValidationError from "../ValidationError/ValidationError";
import { format } from "../../utilities/utils";
import { pushNewCTAEvent } from "../../adobeAnalytics";

interface UiElementProp {
  UIObjects: any;
  UpdateQuoteFn: any;
  parentObject: any;
  id?: string;
  policyData?: any;
  inruleDataState?: any;
}

interface Message {
  Type: any;
  Code: any;
  Text: any;
}

// const StampDutyNote = styled.div`
// font-size: 9px;
// `;
let errorText = "";
window.digitalData = window.digitalData || [];
const getWarningMessages = (UIObject: any) => {
  if (!UIObject || !UIObject.Message || !Array.isArray(UIObject.Message)) {
    return []; // Return an empty array if UIObject or UIObject.Message is null, undefined, or not an array
  }
  if (UIObject && UIObject.Message !== null && UIObject.Message.Type !== null) {
    if (
      UIObject?.Message?.some((message: Message) => message.Type === "Warning")
    ) {
      // const warningMessages = UIObject.Message.filter((message: { Type: any }) => { message.Type === "Warning"});
      const warningMessages = UIObject?.Message?.filter(
        (message: { Type: any }) => {
          return (
            message.Type !== null &&
            message.Type !== undefined &&
            message.Type === "Warning"
          );
        }
      );
      let bulletSymbol = warningMessages.length > 1 ? "-" : "";
      if (warningMessages.length > 0) {
        const formattedMessages = warningMessages.map(
          (message: Message) => `${bulletSymbol} ${message.Text}`
        );
        // console.log(formattedMessages)
        // console.log(warningMessages)
        const bulletPoints = formattedMessages.join("\n");
        return (
          // DISABLE FOR RELEASE
          <GelTooltip top icon="AlertTriangle" color={"orange"}>
          {/* <GelTooltip top icon="AlertCircle" color={"orange"}> */}
            <div>
              {/* style={{backgroundColor:"orange", color:"black"}} */}
              <ul>
                {formattedMessages.map((message: { Type: any }) => (
                  <li>{message}</li>
                ))}
                {/* {bulletPoints.map((message:Message) => (
                                <li>{message.Text}</li>
                            ))} */}
              </ul>
            </div>
          </GelTooltip>
        );
      } else return <></>;
    }
  }
};

export const handleUIChanges = (
  event: any,
  uiObject: any,
  UpdateQuoteFn: any,
  parentObject: any,
  policyData?: any,
  setSelectValue?: Function
) => {
  // Adobe Analytics Data layer -> pushing to digitalData
  let clickText = uiObject.Label ? uiObject.Label : uiObject.Name

  if (uiObject.ObjectType === "RadioButton") { // For radio button
    clickText = uiObject.Source
  } 

  let coverType = parentObject.UIObjects[0].Value 
  let clickSection = 'Policy Details'

  if (parentObject.CoverType) {
    clickSection = `${coverType} Cover Details`
  } else if (uiObject.FrameName) {
    let frameName = uiObject.FrameName.split(/(?=[A-Z])/)
    clickSection = frameName.join(" ")
  }
  
  pushNewCTAEvent(clickText, uiObject.ObjectType, clickSection)

  let updateQuoteModel: string = "";
  const isDirty =
    uiObject.ObjectType === "CheckBox"
      ? true
      : uiObject.ObjectType === "GroupButton"
      ? uiObject.Value !== "true"
      : event.target.value !== uiObject.Value;
  let value = "";

  if (!isDirty) {
    return;
  }

  if (uiObject?.ObjectType === "DropDownList" && setSelectValue) {
    setSelectValue(event.target.value);
  }

  if (
    uiObject.ObjectType === "GroupButton" ||
    uiObject.ObjectType === "RadioButton"
  ) {
    parentObject.UIObjects.filter(
      (item: any) => item.GroupName === uiObject.GroupName
    ).map((item: any) => {
      item.Value = item.Name === uiObject.Name ? "true" : "false";
      item.IsChanged = true;
    });
  } else if (uiObject.ObjectType === "Button") {
    // Added as part of DGOTO2649 - New Button Instance for Add and Remove Cover and life
    uiObject.IsChanged = true;
    updateQuoteModel = "UPDATE_QUOTE_MODEL"; // DGOTO 3970 added to differntiate add cover
  } else {
    value =
      uiObject.ObjectType === "CheckBox"
        ? event.target.checked.toString()
        : event.target.value;
    if (uiObject.ObjectType === "AmountTextBox" && value == "$") {
      value = "";
    }
    uiObject.Value = value;
    uiObject.IsChanged = true;
  }

  // if (value !== "") {
  //   UpdateQuoteFn();
  // } else {
  //   alert("form has validation errors");
  // }
  UpdateQuoteFn(updateQuoteModel); // DGOTO 3970 added to differntiate add cover
};

const UiElement = ({
  UIObjects,
  UpdateQuoteFn,
  parentObject,
  id,
  policyData,
  inruleDataState,
}: UiElementProp) => {
  // const textInput: any = useRef();
  // const dropdownList: any = useRef();
  // const radioButtonGroup: any = useRef();
  // const checkboxGroup: any = useRef();
  const dateInput: any = useRef();
  const [textInputValue, setTextInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectValue, setSelectValue] = useState("");
  // const [errorMessageTextBox, setErrorMessageTextBox] = useState("");
  // const [errorMessageRadioButton, setErrorMessageRadioButton] = useState("");
  // const [errorMessageCheckBox, setErrorMessageCheckBox] = useState("");
  // const [errorMessageDropDownList, setErrorMessageDropDownList] = useState("");

  useEffect(() => {
    // if (textInput) {
    //   textInput?.current?.focus();
    //   textInput?.current?.blur();
    // }
    // if (dropdownList) {
    //   dropdownList?.current?.focus();
    //   dropdownList?.current?.blur();
    // }
    setSelectValue(UIObjects?.Value);
  }, [UIObjects?.Value]);

  const handleTextInputChange = (
    value: string,
    type: string,
    formatting: string
  ) => {
    if (type === "AmountTextBox") {
      // alert(`$${value}`)
      setTextInputValue(format(value, formatting));
    } else {
      setTextInputValue(value);
    }
  };

  const setAmountTextBoxValue = (
    textInputValue: string,
    uiobject: any,
    setTextInputValue: Function
  ) => {
    if (uiobject?.ObjectType === "AmountTextBox") {
      if (uiobject?.IsDisabled && uiobject?.Value !== textInputValue) {
        setTextInputValue(uiobject?.Value);
        textInputValue = uiobject?.Value;
        return format(uiobject?.Value, uiobject?.Formatting);
      } else {
        if (textInputValue === "") {
          textInputValue = uiobject?.Value;
          // return format(textInputValue, uiobject?.formatting)
          return format(textInputValue, uiobject?.Formatting);
        } else {
          return textInputValue;
        }
      }
    }
  };

  const getUiElementGroupErrMessage = (radioGroup: any) => {
    let errMsg = [];
    for (let i = 0; i < radioGroup.length; i++) {
      if (radioGroup[i]?.Message && radioGroup[i]?.Message?.length > 0) {
        errMsg =
          radioGroup[i]?.Message[0]?.Type === "Error"
            ? radioGroup[i]?.Message
            : [];
        return errMsg;
      }
    }
  };

  const getErrorMessage = (UIObject: any) => {
    let message = [];
    if (UIObject?.Message && UIObject?.Message?.length) {
      message = UIObject?.Message;
    }
    if (
      // UIObject?.Source === "SumInsured" &&
      message &&
      message[message.length - 1]?.Type === "Error"
    ) {
      const errText = message[message.length - 1]?.Text;
      if (errText !== "") {
        // switch (UIObject?.ObjectType) {
        //   case "TextBox":
        //     setErrorMessageTextBox(errText);
        //     break;

        //   case "CheckBox":
        //     setErrorMessageCheckBox(errText);
        //     break;

        //   case "RadioButton":
        //     setErrorMessageRadioButton(errText);
        //     break;

        //   case "DropDownList":
        //     // alert(errText);
        //     setErrorMessageDropDownList(errText);
        //     break;
        // }
        setErrorMessage(errText);
      }
      // setErrorMessages(errorMessages.push({[UIObject?.Source] : UIObject?.Message[0]?.Text}))
      errorText = errText;
      return errText;
    } else return "";
  };

  if (Array.isArray(UIObjects)) {
    let selectedObject =
      UIObjects.find((item: any) => item.Value === "true") ?? "true";
    let messageSelectedObject: Message = selectedObject.Message;
    return (
      <>
        <GelRadioGroup
          // vertical
          required={selectedObject?.Value === null}
          // ref={radioButtonGroup}
          // errorMsg={{
          //   required: errorMessage,
          // }}
          id={`GelRadioGroup-${parentObject?.Id}-${UIObjects[0]?.FrameName}-${UIObjects[0]?.GroupName}`}
          defaultValue={selectedObject?.Name}
          value={selectedObject?.Name}
          // disabled={selectedObject?.disabled}
          vertical
          onError={(e: any) => {
            getErrorMessage(selectedObject);
          }}
        >
          {UIObjects.map((UIObject: any, index: number) => {
            return (
              <GelRadio
                key={index}
                id={`${id}-${parentObject?.Id}-${UIObject?.ObjectType}-${UIObject?.Name}-${index}`}
                value={selectedObject ? UIObject.Name : selectedObject?.Name}
                disabled={UIObject?.IsDisabled}
                onChange={(e: any) =>
                  handleUIChanges(
                    e,
                    UIObject,
                    UpdateQuoteFn,
                    parentObject,
                    policyData
                  )
                }
              >
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px" }}>{UIObject.Label}</div>
                  <div style={{ paddingTop: "1px" }}>
                    {getWarningMessages(UIObject)}
                  </div>
                </div>
              </GelRadio>
            );
          })}
        </GelRadioGroup>
        <ValidationError
          errors={
            //Todo
            selectedObject?.Message === "Error"
              ? selectedObject?.Message
              : "" || getUiElementGroupErrMessage(UIObjects)
          }
        />
      </>
    );
  } else {
    let UIObject = UIObjects;

    switch (UIObject?.ObjectType) {
      case "StaticText":
        return UIObject?.Label === "" ? (
          <GelParagraph
            style={{
              color: "inherit",
              display: `${UIObject.IsHidden == true ? "none" : "block"}`,
            }}
            id={`${UIObject?.Name}-${parentObject?.Id}`}
          >
            {UIObject?.Value}
          </GelParagraph>
        ) : (
          <GelLabel
            style={{
              color: "inherit",
              display: `${UIObject.IsHidden == true ? "none" : "block"}`,
            }}
            id={`${UIObject?.Name}-${parentObject?.Id}`}
          >
            {UIObject?.Label}
          </GelLabel>
        );

      case "Button": // Added as part of DGOTO2649 - New Button Instance for Add and Remove Cover and life
        return UIObject?.FrameName !== "CoverHeader" &&
          UIObject?.FrameName !== "LifeInsuredDetails" ? (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <GelButton
              id={`${id}-${parentObject?.Id}-${UIObject?.ObjectType}-${UIObject?.Name}`}
              disabled={UIObject?.IsDisabled}
              style={{
                display: `${UIObject.IsHidden == true ? "none" : "block"}`,
              }}
              primary
              medium
              stretch="true"
              onClick={(e: any) => {
                handleUIChanges(
                  e,
                  UIObject,
                  UpdateQuoteFn,
                  parentObject,
                  policyData
                );
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px" }}>{UIObject.Label}</div>
                <div style={{ paddingTop: "1px" }}>
                  {getWarningMessages(UIObject)}
                </div>
              </div>
            </GelButton>
            <ValidationError
              errors={UIObject?.Message?.filter(
                (message: Message) => message.Type === "Error"
              )}
            />
          </div>
        ) : (
          <></>
        );
      case "GroupButton":
        return UIObject?.FrameName !== "CoverHeader" &&
          UIObject?.FrameName !== "LifeInsuredDetails" ? (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <GelButton
              id={`${id}-${parentObject?.Id}-${UIObject?.ObjectType}-${UIObject?.Name}`}
              className={UIObject?.Value !== "true" ? "disabledbtn" : ""}
              disabled={UIObject?.IsDisabled}
              style={{
                display: `${UIObject.IsHidden == true ? "none" : "block"}`,
              }}
              primary
              medium
              stretch="true"
              onClick={(e: any) => {
                handleUIChanges(
                  e,
                  UIObject,
                  UpdateQuoteFn,
                  parentObject,
                  policyData
                );
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px" }}>{UIObject.Label}</div>
                <div style={{ paddingTop: "1px" }}>
                  {getWarningMessages(UIObject)}
                </div>
              </div>
            </GelButton>
            <ValidationError
              errors={
                UIObject?.Message || getUiElementGroupErrMessage(UIObjects)
              }
            />
          </div>
        ) : (
          <></>
        );

      case "CheckBox":
        //!UIObject.IsHidden ?
        return (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <GelCheckboxGroup
              name="" // Makes it a managed component
              vertical
              onChange={() => getErrorMessage(UIObject)}
              required={UIObject?.Value === null}
              // ref={checkboxGroup}
              disabled={UIObject?.IsDisabled}
              style={{
                display: `${UIObject.IsHidden == true ? "none" : "block"}`,
              }}
              // errorMsg={{
              //   required: true
              // }}
              onError={(e: any) => {
                getErrorMessage(UIObject);
              }}
            >
              <GelCheckbox
                id={`${id}-${parentObject?.Id}-${UIObject?.ObjectType}-${UIObject?.Name}`}
                defaultChecked={UIObject?.Value === "false" ? false : true}
                disabled={UIObject?.IsDisabled}
                key={UIObject?.Value}
                onChange={(e: any) => {
                  handleUIChanges(
                    e,
                    UIObject,
                    UpdateQuoteFn,
                    parentObject,
                    policyData
                  );
                }}
                style={{
                  alignItems: "flex-end",
                  height: "100%",
                  display: `${UIObject.IsHidden == true ? "none" : "block"}`,
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px" }}>{UIObject.Label}</div>
                  <div style={{ paddingTop: "1px" }}>
                    {getWarningMessages(UIObject)}
                  </div>
                </div>
              </GelCheckbox>
            </GelCheckboxGroup>
            <ValidationError
              errors={
                UIObject?.Message !== null
                  ? //UIObject?.Message || getUiElementGroupErrMessage(UIObjects)
                    UIObject?.Message?.some(
                      (message: Message) => message.Type === "Error"
                    ) || getUiElementGroupErrMessage(UIObjects)
                  : ""
              }
            />
          </div>
        );
      // : (
      //   <></>
      // );

      case "TextBox":
      case "AmountTextBox":
        return (
          <div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div>
                <GelTextInput
                  id={`${UIObject?.ObjectType}-${parentObject?.Id}-${
                    UIObject?.Name
                  }${id ? `-${id}-` : `${UIObject?.Value}`}-${JSON.stringify(
                    UIObject?.Message
                  )}`}
                  style={{
                    display: `${UIObject.IsHidden == true ? "none" : "block"}`,
                  }}
                  defaultValue={UIObject?.Value}
                  required={UIObject?.IsMandatory}
                  // regex={textInputValue === "0"}
                  regex={validateRegExp(textInputValue, UIObject)}
                  disabled={UIObject?.IsDisabled}
                  // errorMsg={{
                  //   required: errorMessage || errorText || "This field is required",
                  //   regex:
                  //     /*textInputValue === "0" */ validateRegExp(
                  //       textInputValue,
                  //       UIObject
                  //     ) &&
                  //     (errorMessage || errorText),
                  // }}
                  // ref={textInput}
                  onBlur={(e: any) => {
                    handleUIChanges(
                      e,
                      UIObject,
                      UpdateQuoteFn,
                      parentObject,
                      policyData
                    );
                    getErrorMessage(UIObject);
                  }}
                  onChange={(e: any) => {
                    handleTextInputChange(
                      e.target.value,
                      UIObject?.ObjectType,
                      UIObject?.Formatting
                    );
                  }}
                  value={setAmountTextBoxValue(
                    textInputValue,
                    UIObject,
                    setTextInputValue
                  )}
                  onFocus={(e: any) => {
                    mapRegExp(UIObject);
                    getErrorMessage(UIObject);
                  }}
                  onError={(e: any) => {
                    getErrorMessage(UIObject);
                  }}
                />
              </div>
              <div style={{ marginLeft: "5px", marginTop: "8px" }}>
                {getWarningMessages(UIObject)}
              </div>
              {/* <ValidationError errors={UIObject?.Message} /> */}
            </div>
            <ValidationError
              errors={UIObject?.Message?.filter(
                (message: Message) => message.Type === "Error"
              )}
            />
          </div>
        );

      case "DropDownList":
        return (
          <div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div>
                <GelSelect
                  placeholder="Select"
                  id={`${UIObject?.ObjectType}-${parentObject?.Id}-${
                    UIObject?.Name
                  }${id ? `-${id}-` : `${UIObject?.Value}`}`}
                  defaultValue={UIObject?.Value}
                  value={UIObject?.Value || selectValue}
                  style={{
                    display: `${UIObject.IsHidden == true ? "none" : "block"}`,
                  }}
                  required={UIObject?.IsMandatory}
                  disabled={UIObject?.IsDisabled}
                  // ref={dropdownList}
                  // errorMsg={{
                  //   required: errorMessage || "This field is required",
                  // }}
                  onChange={(e: any) =>
                    handleUIChanges(
                      e,
                      UIObject,
                      UpdateQuoteFn,
                      parentObject,
                      policyData,
                      setSelectValue
                    )
                  }
                  onError={(e: any) => {
                    getErrorMessage(UIObject);
                  }}
                  onFocus={(e: any) => {
                    getErrorMessage(UIObject);
                  }}
                  onBlur={(e: any) => {
                    getErrorMessage(UIObject);
                  }}
                  options={convertDropdownList(UIObject?.ValueList)}
                />
              </div>
              <div style={{ marginLeft: "5px", marginTop: "8px" }}>
                {getWarningMessages(UIObject)}
              </div>
              {/* {JSON.stringify(UIObject?.ValueList)} */}
            </div>
            <ValidationError
              errors={UIObject?.Message?.filter(
                (message: Message) => message.Type === "Error"
              )}
            />
          </div>
        );

      case "DatePicker":
        return (
          <>
            <GelForm>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div style={{ minWidth: 0 }}>
                  <GelStandardDateInput
                    id={`${UIObject?.ObjectType}-${parentObject?.Id}-${
                      UIObject?.Name
                    }${id ? `-${id}-` : `${UIObject?.Value}`}`}
                    value={UIObject?.Value}
                    ref={dateInput}
                    disabled={UIObject?.IsDisabled}
                    required={UIObject?.IsMandatory}
                    style={{
                      display: `${
                        UIObject.IsHidden == true ? "none" : "block"
                      }`,
                    }}
                    // errorMsg={{
                    //   required: "This field is required",
                    // }}
                    // onBlur={(e: any) =>
                    //   handleUIChanges(
                    //     e,
                    //     UIObject,
                    //     UpdateQuoteFn,
                    //     parentObject,
                    //     policyData
                    //   )
                    // }
                    onChange={(e: any) =>
                      handleUIChanges(
                        e,
                        UIObject,
                        UpdateQuoteFn,
                        parentObject,
                        policyData
                      )
                    }
                    onError={(e: any) => {
                      getErrorMessage(UIObject);
                    }}
                  />
                </div>
                <div style={{ marginLeft: "5px", marginTop: "10px" }}>
                  {getWarningMessages(UIObject)}
                </div>
              </div>
            </GelForm>
            {/* <ValidationError errors={UIObject?.Message} /> */}

            <ValidationError
              errors={UIObject?.Message?.filter(
                (message: Message) => message.Type === "Error"
              )}
            />
          </>
        );

      default:
        return <></>;
    }
  }
};

export default UiElement;
