import { call, put, takeEvery } from "@redux-saga/core/effects";
import { IPasPolicyState } from "./reducer";
import { AnyAction } from "redux";
import {
  fetchPolicyDetailsSuccess,
  GET_POLICY_DETAILS,
  fetchPolicyDetailsError,
  GET_AssociatedPolicy_DETAILS,
  fetchAssociatedPolicyDetailsSuccess,
  fetchAssociatedPolicyDetailsError,
  UPDATE_QUOTE,
  fetchUpdatedQuoteError,
  fetchUpdatedQuoteSuccess,
} from "./actions";
import {
  getPolicyDetails,
  updateQuote,
  getAssociatedPolicyDetails,
} from "../../services/policyService";

// TODO define redux state and dapi handler for each type of validation

export function* getPolicyDetailsHandler(action: AnyAction) {
  try {
    const policyDetails: IPasPolicyState = yield call(
      getPolicyDetails,
      action.response
    );

    // if (policyDetails?.getPasPolicyDetails?.getupdatedpremiumdetails?.Messages?.length > 0)
    //   yield put(fetchPolicyDetailsError(policyDetails?.getPasPolicyDetails?.getupdatedpremiumdetails));
    // else
    yield put(fetchPolicyDetailsSuccess(policyDetails));
  } catch (error) {
    yield put(fetchPolicyDetailsError(error));
  }
}

export function* getAssociatedPolicyDetailsHandler(action: AnyAction) {
  try {
    const policyDetails: IPasPolicyState = yield call(
      getAssociatedPolicyDetails,
      action.response.policyNumber,
      action.response.quoteModel
    );

    // if (policyDetails?.data?.getAssociatedPolicyDetails?.Messages?.length > 0)
    //   yield put(fetchAssociatedPolicyDetailsError(policyDetails?.data?.getAssociatedPolicyDetails));
    // else
    yield put(fetchAssociatedPolicyDetailsSuccess(policyDetails));
  } catch (error) {
    yield put(fetchAssociatedPolicyDetailsError(error));
  }
}

export function* updateQuoteHandler(action: AnyAction) {
  try {
    const policyDetails: IPasPolicyState = yield call(
      updateQuote,
      action.response
    );

    // if (policyDetails?.data?.getupdatedpremiumdetails?.Messages?.length > 0)
    //     yield put(fetchUpdatedQuoteError(policyDetails?.data?.getupdatedpremiumdetails));
    // else
    // DGOTO 3970 added condition to differntiate add cove
    yield put(
      fetchUpdatedQuoteSuccess(policyDetails, action.isUpdateQuoteModel)
    );
  } catch (error) {
    yield put(fetchUpdatedQuoteError(error));
  }
}

export const sagas = [
  takeEvery(GET_POLICY_DETAILS, getPolicyDetailsHandler),
  takeEvery(UPDATE_QUOTE, updateQuoteHandler),
  takeEvery(GET_AssociatedPolicy_DETAILS, getAssociatedPolicyDetailsHandler),
];
