import styled, { css } from "styled-components";
import {
  GelContainerLite,
  GelCol,
  GelLink,
  GelParagraph,
  GelLabel,
  GelColumnLayout,
  GelRowLayout,
} from "@tal-gel/components";
import { connect } from "react-redux";
import { fetchAssociatedPolicyDetails,fetchPolicyDetails } from "../../store/policy/actions";
import { pushNewCTAEvent } from "../../adobeAnalytics";

const CustomGelAssociatedPolicyContainerLite = styled(GelContainerLite)(
  (props) => css`
    border: 1px solid gray;
    height: auto;
  `
);

export const AssociatedPolicies = (state: any) => {
  const uiObjects = state?.policyResponse?.data?.Quote?.UIObjects.filter(
    (item: any) => item.FrameName === "AssociatedPolicy"
  );

  // const onClick = (policyRefNumber: any) => {
  //   state.fetchAssociatedPolicy({
  //     policyNumber: policyRefNumber,
  //     quoteModel: state?.policyResponse?.data,
  //   });
  // };

  const onClick = (policyRefNumber: any) => {
    state.fetchPolicyDetails(
      policyRefNumber
      //quoteModel: state?.policyResponse?.data,
    );
    // // Adobe Analytics Data layer -> pushing to digitalData.events
    pushNewCTAEvent("Associated Policy", "Link", "Navigation Bar")
  };

  return (
    <CustomGelAssociatedPolicyContainerLite gutter="small">
      <GelRowLayout gutter="small">
        {
          <GelLabel>
            {
              uiObjects?.find(
                (item: any) => item.Name === "AssociatedPolicy_Label"
              ).Label
            }
          </GelLabel>
        }
        {/* </GelRowLayout>
      <GelRowLayout> */}
        {uiObjects
          ?.filter((item: any) => item.Name !== "AssociatedPolicy_Label")
          .map((uiObject: any, index: number) => {
            return (
              <GelCol sm={12} md={12} lg={12}>
                <GelLink
                  onClick={(e: any) => {
                    onClick(uiObject.Value);
                  }}
                >
                  {uiObject.Value}
                </GelLink>
              </GelCol>
            );
          })}
      </GelRowLayout>
    </CustomGelAssociatedPolicyContainerLite>
  );
};

export const mapStateToProps = (state: any) => ({
  policyResponse: state.policyResponse,
});

// export const mapDispatchToProps = (dispatch: any) => {
//   return {
//     fetchAssociatedPolicy: (policyResponse: any) =>
//       dispatch(fetchAssociatedPolicyDetails(policyResponse)),
//   };
// };

export const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchPolicyDetails: (policyResponse: any) =>
      dispatch(fetchPolicyDetails(policyResponse)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AssociatedPolicies);
