import {
  GelContainerLite,
  GelRowLayout,
  GelParagraph,
} from "@tal-gel/components";

const Footer = () => {
  return (
    <GelContainerLite gutter="medium">
      <GelRowLayout gutter="small">
        <GelParagraph>Footer content</GelParagraph>
      </GelRowLayout>
    </GelContainerLite>
  );
};
export default Footer;
