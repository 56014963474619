import { GelContainerLite, GelRow, GelRowLayout } from "@tal-gel/components";
import CardElements from "../shared/CardElements";

interface PolicyHeaderFooterData {
  UIObjects: any;
  policy: any;
  UpdateQuote: any;
  backgroundColor: any;
  fontColor?: any;
  id: string;
}

const PolicyHeaderFooter = ({
  UIObjects,
  policy,
  UpdateQuote,
  backgroundColor,
  fontColor,
  id,
}: PolicyHeaderFooterData) => {
  return (
    <GelRowLayout id={id} style={{ backgroundColor: backgroundColor }}>
      <GelContainerLite
        style={{ color: fontColor ? fontColor : "black" }}
        gutter="medium"
        className="coverHeader"
      >
        <GelRow gutter="small">
          {UIObjects.map((UIObject: any, index: number) => {
            return (
              <CardElements
                UIObjects={UIObject}
                UpdateQuoteFn={UpdateQuote}
                parentObject={policy}
              />
            );
          })}
        </GelRow>
      </GelContainerLite>
    </GelRowLayout>
  );
};
export default PolicyHeaderFooter;
