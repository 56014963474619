import styled, { css } from "styled-components";
import UIElement from "../shared/UIElement";
import {
  GelRow,
  GelCol,
  GelAccordion,
  GelContainerLite,
} from "@tal-gel/components";

const CustomGelCol = styled(GelCol)(
  (props) =>
    css`
      margin-bottom: 8px;
      vertical-align: bottom;
      min-width: 0;
    `
);
interface CoverCommission {
  cover: any;
  UIObjects: any;
  UpdateQuoteCallback: any;
  id?: any;
  life: any;
}

const CoverCommission = ({
  cover,
  UIObjects,
  UpdateQuoteCallback,
  id,
  life
}: CoverCommission) => {
  const header = UIObjects.find(
    (item: any) =>
      item.FrameName === "CoverCommissionHeader" ||
      item.FrameName === "CoverDiscountHeader"
  );

  return (
    <GelRow>
      <GelCol sm={12} md={12} lg={12}>
        <GelAccordion id={id} title={header?.Label} removeContentPadding>
          <GelContainerLite gutter="small">
            <GelRow gutter="small">
              {UIObjects?.filter(
                (item: any) =>
                  item.FrameName !== "CoverCommissionHeader" &&
                  item.FrameName !== "CoverDiscountHeader"
              ).map((UIObject: any, colIndex: number) => {
                return (
                  <CustomGelCol sm={6} md={12} lg={UIObject.LabelGridCellWidth}>
                    <UIElement
                      UIObjects={UIObject}
                      UpdateQuoteFn={UpdateQuoteCallback}
                      parentObject={cover}
                      grandParentObject={life}
                    />
                  </CustomGelCol>
                );
              })}
            </GelRow>
          </GelContainerLite>
        </GelAccordion>
      </GelCol>
    </GelRow>
  );
};

export default CoverCommission;
