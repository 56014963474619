import styled, { css } from "styled-components";

interface CardMarginProps {
  margin: string;
  // add more
}

const CardMarginComponent = styled.span(
  (props: CardMarginProps) =>
    css`
      margin: ${props.margin};
      display: block;
    `
);

const CardMargin = ({ margin }: CardMarginProps) => {
  return <CardMarginComponent margin={margin} />;
};

export default CardMargin;
