import { getUrlParmValue } from "../utilities/utils";

const inrule = window["inrule" as any] as any;
export default inrule; // just in case if any other module wants to import this variable

export const applyRulesWrapper = (modelName: string, viewModel: any) => {
  const inrule = window["inrule" as any] as any;
  const session = inrule?.createRuleSession();
  session?.createEntity(modelName, viewModel);

  session?.applyRules((log: any) => {
    const host = window.location.hostname;
    let messagesArray = [];
    let enableInRuleLog: string = getUrlParmValue("irLog") as any;
    let environmentLog = process?.env?.NODE_ENV;
    debugger;
    console.log(`process.env  NODE_ENV is ${environmentLog}`);
    if (
      environmentLog === "development" ||
      window.location.host.startsWith("qa-") ||
      window.location.host.startsWith("dev-")
    ) {
      for (let i = 0; i < log.messages.length; i++) {
        let logItem = "";
        logItem = `${i} - ${log.messages[i]}`;
        messagesArray.push(logItem);
      }
      console.log(messagesArray);
    }

    if (process.env?.REACT_APP_ENV_Stage === "true") {
      console.log("inrule logs", enableInRuleLog, log);
    }

    // Note: appending irLog=true will output the inRule log.
    if (enableInRuleLog === "true") {
      if (
        host === "dev1-adviser.delivery.lan" ||
        host === "qa1-adviser.delivery.lan" ||
        host === "adviser-local.delivery.lan" ||
        host === "tac.ui.tal.com.au" ||
        host === "localhost"
      ) {
        if (process.env?.REACT_APP_ENV_Stage === "true") {
          console.log("InRuleLog", JSON.stringify(log, null, "\t"));
        }
      }
    }
  });
};

export const applyQuoteLifeCoverModelRules = (quoteViewModel: any) =>
  applyRulesWrapper("QuoteModel", quoteViewModel);

export const applyQuoteModelRules = (quoteModel: any) =>
  applyRulesWrapper("quoteViewModel", quoteModel);
