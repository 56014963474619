import {
  GelContainerLite,
  GelBoxLayout,
  GelHeading5,
  GelParagraph,
} from "@tal-gel/components";

import { connect } from "react-redux";

const Header = (state: any) => {
  const errors = state?.policyResponse?.errors;
  
  return (
    <GelContainerLite gutter="medium">
      <GelBoxLayout gap="large" space="auto" distribution="start">
        <GelHeading5
          id="EBQLogoHeader"
          style={{
            marginTop:
              errors && Object.keys(errors)?.length > 1 ? "60px" : "30px",
          }}
        >
          EBQ
        </GelHeading5>
      </GelBoxLayout>
    </GelContainerLite>
  );
};

export const mapStateToProps = (state: any) => ({
  policyResponse: state.policyResponse,
});

export default connect(mapStateToProps)(Header);
