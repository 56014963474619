import { EventHandler, useState } from "react";
import { GelSwitch, GelContainerLite, GelBoxLayout } from "@tal-gel/components";

import styled, { css } from "styled-components";
import { pushNewCTAEvent } from "../../adobeAnalytics";

const CustomGelContainerLite = styled(GelContainerLite)(
  (props) => css`
    margin-top: -20px;
    margin-bottom: 30px;
  `
);

interface ToggleProps {
  rightLabel: string;
  callback: any; //Added to capture and send the Show/Hide ToggleSwtichValue to parent component
}

const ToggleSwitch = (props: ToggleProps) => {

  const handleOnChange = (event: any) => {
    // Adobe Analytics Data layer -> pushing to digitalData.events
    pushNewCTAEvent("Include Adviser Details", "Toggle Switch", "Navigation Bar")
    props.callback(!event.target.checked)
  }
  return (
    <>
      <GelBoxLayout>
        <GelSwitch
          labelOnRight
          defaultOn
          onChange={(event: any) => handleOnChange(event)} //Added to props.callback capture and send the Show/Hide ToggleSwtichValue to parent component
        >
          {props.rightLabel}
        </GelSwitch>
      </GelBoxLayout>
    </>
  );
};

export default ToggleSwitch;
