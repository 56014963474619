import UIElement from "./UIElement";
import { GelCol, GelButton, GelLabel } from "@tal-gel/components";
import { useState } from "react";
import styled, { css } from "styled-components";
import GenericComponents from "./GenericComponents";

const CustomGelButtonRemove = styled(GelButton)(
  (props) => css`
    position: absolute !important;
    margin-top: -30px;
    margin-left: 360%;
    width: 100px !important;

    @media (max-width: 1350px) {
      width: 70px !important;
      margin-top: -20px;
      position: absolute;
    }
  `
);

interface CardElementsData {
  UIObjects: any;
  UpdateQuoteFn: any;
  parentObject: any;
  id?: string;
  key?: string;
  policyData?: any;
  inruleDataState?: any;
  grandParentObject?: any;
}

export const handleUIChanges = (
  event: any,
  uiObject: any,
  UpdateQuoteFn: any,
  parentObject: any,
  policyData?: any,
  setSelectValue?: Function,
  grandParentObject?: any
) => {
  // Added as part of DGOTO3115 - EBQ Remove Cover - React - Click on Event Action on "Remove" button under Cover Card
  //Set IsChanged to true once the Remove button is clicked
  uiObject.IsChanged = uiObject.ObjectType === "Button";
  UpdateQuoteFn();
};


export enum UIMessageType {
  Confirmation = "Confirmation",
  Error = "Error",
  Tooltip = "Tooltip",
}

const CardElements = ({
  parentObject,
  UIObjects,
  UpdateQuoteFn,
  id,
  key,
  policyData,
  inruleDataState,
  grandParentObject
}: CardElementsData) => {
  const [openModal, setOpenModal] = useState(false);
  const [showGenericComponent, setShowGenericComponent] = useState(false);

  const cellWidth = Array.isArray(UIObjects)
    ? 12
    : UIObjects.LabelGridCellWidth;
  const UIObject = Array.isArray(UIObjects) ? UIObjects[0] : UIObjects;
  const removeCoverButtonUIObject =
    !Array.isArray(UIObjects) && UIObjects.Name === "RemoveCover"
      ? UIObjects
      : null;
  let eventButton: any = null;

  return UIObject.IsHidden ? (
    <></>
  ) : (
    <>
      <GelCol
        key={key}
        sm={6}
        md={12}
        lg={cellWidth}
        style={{ marginBottom: "10px" }}
      >
        {(UIObject.ObjectType === "TextBox" ||
          UIObject.ObjectType === "AmountTextBox" ||
          UIObject.ObjectType === "DropDownList" ||
          UIObject.ObjectType === "DatePicker") &&
        UIObject.Label !== "" ? (
          <GelLabel
            key={key}
            id={`${UIObject?.ObjectType}-label-${UIObject?.Label}`}
          >
            {UIObject.Label}
          </GelLabel>
        ) : (
          //Code Review DGOTO-3018: Use GelLabel instead of label

          // <label
          //   key={key}
          //   id={`${UIObject?.ObjectType}-label-${UIObject?.Label}`}
          // >
          //   {UIObject.Label}
          // </label>
          <></>
        )}
        <UIElement
          UIObjects={UIObjects}
          UpdateQuoteFn={UpdateQuoteFn}
          parentObject={parentObject}
          id={`UIElement-${UIObject.Name}`}
          policyData={policyData}
          inruleDataState={inruleDataState}
          grandParentObject={grandParentObject}
        />
        <GelCol lg={2}>
          {removeCoverButtonUIObject && removeCoverButtonUIObject?.Label && (
            <CustomGelButtonRemove
              id={`${id}-${parentObject?.Id}-${removeCoverButtonUIObject?.ObjectType}-${removeCoverButtonUIObject?.Name}`}
              disabled={removeCoverButtonUIObject?.IsDisabled}
              primary
              medium
              stretch="true"
              key={key}
              onClick={(e: any) => {
                eventButton = e;
                if (
                  Array.isArray(removeCoverButtonUIObject?.Message) &&
                  removeCoverButtonUIObject?.Message != null
                ) {
                  for (
                    let i = 0;
                    i < removeCoverButtonUIObject.Message.length;
                    i++
                  ) {
                    const message = removeCoverButtonUIObject.Message[i];
                    // <GenericReactElemntProvider messages={removeCoverButtonUIObject.Message} />
                    if (message.Type === UIMessageType.Confirmation) {
                      setShowGenericComponent(true);
                      break;
                    }
                  }
                }
                setOpenModal(true);
                //TODD: should be removed after testing, Modal will handle it

                // handleUIChanges(
                //   e,
                //   removeCoverButtonUIObject,
                //   UpdateQuoteFn,
                //   parentObject,
                //   policyData
                // );
              }}
            >
              {removeCoverButtonUIObject?.Label}
            </CustomGelButtonRemove>
          )}
          {showGenericComponent && (
            <GenericComponents
              show={openModal}
              setShow={setOpenModal}
              messages={removeCoverButtonUIObject.Message}
              action={() =>
                handleUIChanges(
                  eventButton,
                  removeCoverButtonUIObject,
                  UpdateQuoteFn,
                  parentObject,
                  policyData
                )
              }
            />
          )}
        </GelCol>
      </GelCol>
    </>
  );
};
export default CardElements;
