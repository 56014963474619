import {
  GelContainerLite,
  GelRowLayout,
  GelRow,
  GelCol,
} from "@tal-gel/components";
import CardFrame from "../shared/CardFrame";
import { getGelTokens } from "@tal-gel/theming";
import { PoicyCardBodyTheme } from "../../themes/Cards.styled";

interface PolicyDetailsData {
  policy: any;
  UIObjects: any;
  UpdateQuote: any;
}

const PolicyDetails = ({
  policy,
  UIObjects,
  UpdateQuote,
}: PolicyDetailsData) => {
  const listA = UIObjects?.filter(
    (item: any) => item.LabelGridCell.substr(0, 1) === "A"
  );

  const listB = UIObjects?.filter(
    (item: any) => item.LabelGridCell.substr(0, 1) === "B"
  ).sort(
    (a: any, b: any) =>
      a.LabelGridCell.substr(a.LabelGridCell.length - 1) -
      b.LabelGridCell.substr(b.LabelGridCell.length - 1)
  );

  return (
    <GelRowLayout
      style={{
        backgroundColor: getGelTokens().global.themeColorBackgroundDefault,
      }}
    >
      <GelContainerLite gutter={"small"}>
        <GelRow>
          <PoicyCardBodyTheme>
            <GelCol sm={6} md={6} lg={6}>
              <CardFrame
                cover={policy}
                list={listA}
                UpdateQuoteFn={UpdateQuote}
              />
            </GelCol>

            <GelCol sm={6} md={6} lg={6}>
              <CardFrame
                cover={policy}
                list={listB}
                UpdateQuoteFn={UpdateQuote}
              />
            </GelCol>
          </PoicyCardBodyTheme>
        </GelRow>
      </GelContainerLite>
    </GelRowLayout>
  );
};
export default PolicyDetails;
