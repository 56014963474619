import React, { useEffect, useState } from "react";
import { GelContainerLite, GelAlert } from "@tal-gel/components";
import { useWindowSize } from "../../custom-hooks/customHooks";

import styled, { css } from "styled-components";

const ResolutionAlertContainerLite = styled(GelContainerLite)(
  (props) => css`
    @media (max-width: 991px) {
      display: block;
    }
    @media (min-width: 992px) {
      display: none;
    }
  `
);

const CustomResolutionAlert = styled(GelAlert)(
  (props) => css`
    position: absolute !important;
    z-index: 99999;
  `
);

const ResolutionAlert: React.FC = () => {
  const [openInfoAlert, setOpenInfoAlert] = useState(true);

  const size = useWindowSize();

  useEffect(() => {
    if (size.width > 0 && size.width < 992) setOpenInfoAlert(true);
  }, [size]);

  return (
    <ResolutionAlertContainerLite>
      <CustomResolutionAlert
        info
        open={openInfoAlert}
        onClose={() => setOpenInfoAlert(false)}
      >
        Resolution not supported
      </CustomResolutionAlert>
    </ResolutionAlertContainerLite>
  );
};

export default ResolutionAlert;
