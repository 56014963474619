import React from "react";
import {
  GelContainerLite,
  GelParagraph,
  GelBoxLayout,
  GelModal,
  GelButton,
  GelTooltip,
} from "@tal-gel/components";
import styled, { css } from "styled-components";

interface GenericComponentsData {
  show: any;
  setShow: any;
  messages: any[];
  action: Function;
}
const CustomGelParagraph = styled(GelParagraph)(
  (props) => css`
    font-size: 35x !important;
    font-weight: 500 !important;
  `
);

export enum UIMessageType {
  Confirmation = "Confirmation",
  Error = "Error",
  Tooltip = "Tooltip",
}

const GenericComponents = ({
  show,
  setShow,
  messages,
  action,
}: GenericComponentsData) => {
  const filteredMessages = messages?.filter(
    (message) => message.Type !== UIMessageType.Error
  );
  const onOk = (e: any) => {
    onCancel();
    action();
    setShow(false);
  };

  const onCancel = () => {
    setShow(false);
  };

  return (
    <GelContainerLite>
      {filteredMessages?.map((message: any, index: any) => {
        let component;
        switch (message.Type) {
          case UIMessageType.Confirmation:
            component = <Modal key={index} />;
            break;
          case UIMessageType.Error:
            component = <ToolTip key={index} />;
            break;
          default:
            component = null; // Or render a default component for unknown types
            break;
        }

        return component;
      })}
    </GelContainerLite>
  );

  function Modal() {
    return (
      <GelModal
        width="md"
        open={show}
        onClose={() => setShow(false)}
        title=""
        action={
          <GelBoxLayout gap="small" space="auto">
            <GelButton primary medium width={90} onClick={onOk}>
              Ok
            </GelButton>
            <GelButton secondary medium width={90} onClick={onCancel}>
              Cancel
            </GelButton>
          </GelBoxLayout>
        }
      >
        <CustomGelParagraph>{filteredMessages[0].Text}</CustomGelParagraph>
      </GelModal>
    );
  }

  function ToolTip() {
    return <GelTooltip topLeft>{filteredMessages[0].Text}</GelTooltip>;
  }
};

export default GenericComponents;
