export function pushNewCTAEvent(clickText: string, clickType: string, clickSection: string): void {
    const CTAObject = {
      clickText,
      clickType,
      clickSection,
    };
  
    // Ensure digitalData.events exists
    if (!window.digitalData.events) {
      window.digitalData.events = [];
    }
  
    let newCTA = {
      cta: CTAObject,
      event: "CTA Click",
    };
  
    window.digitalData.events.push(newCTA);
  }