import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";

import sagas from "./sagas";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import rootReducer from "./reducer";

interface DefaultOptions {
  watchQuery: any;
  query: any;
}

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const client = new ApolloClient({
  link: createHttpLink({
    // uri: `https://localhost:5001/api/retailebqservice/GraphQL`,
    uri: process.env.REACT_APP_GraphQLService_Uri,
    headers: {
      "Ocp-Apim-Subscription-Key": process.env.REACT_APP_SubscriptionKey,
    },
  }),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: defaultOptions,
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeWithDevTools({ maxAge: 200 })(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(sagas);

export default store;
