import { ReportHandler } from "web-vitals";

export const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      if (process.env?.REACT_APP_ENV_Stage === "true") {
        console.log(
          "*****",
          getCLS,
          getLCP,
          getLCP,
          "Stage",
          process.env?.REACT_APP_ENV_Stage
        );
      }
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
