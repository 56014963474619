import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { GelContainerLite } from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import PolicyHeaderFooter from "./PolicyHeaderFooter";
import PolicyDetails from "./PolicyDetails";

export const CustomGelContainerLite = styled(GelContainerLite)(
  (props) => css`
    border: 1px solid gray;
    border-radius: 2px;
  `
);

interface PolicyCardProps {
  policy: any;
  UpdatedQuoteFn: any;
}

export const PolicyCard = ({ policy, UpdatedQuoteFn }: PolicyCardProps) => {
  const headerItems = policy.UIObjects.filter(
    (item: any) => item?.FrameName === "PolicyHeader"
  );
  const policyDetails = policy.UIObjects.filter(
    (item: any) => item?.FrameName === "PolicyDetails"
  );
  const footerItems = policy.UIObjects.filter(
    (item: any) => item?.FrameName === "PolicyFooter"
  );

  return (
    <CustomGelContainerLite
      id="policy-card"
      borderColor={getGelTokens().global.themeColorBackgroundGray}
    >
      <PolicyHeaderFooter
        policy={policy}
        UIObjects={headerItems}
        UpdateQuote={UpdatedQuoteFn}
        backgroundColor={getGelTokens().global.themeColorBackgroundInverse}
        fontColor="white"
        id={`policy-header-${policy?.PolicyRefNo}-${policy?.Id}`}
      />

      <PolicyDetails
        policy={policy}
        UIObjects={policyDetails}
        UpdateQuote={UpdatedQuoteFn}
      />

      <PolicyHeaderFooter
        policy={policy}
        UIObjects={footerItems}
        UpdateQuote={UpdatedQuoteFn}
        backgroundColor={getGelTokens().global.themeColorGrayT20}
        id={`policy-footer-${policy?.PolicyRefNo}-${policy?.Id}`}
      />
    </CustomGelContainerLite>
  );
};

export default connect()(PolicyCard);
