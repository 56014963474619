import {
  IPasPolicyState,
  IQuoteServiceState,
  InRuleState,
} from "./policy/reducer";

export interface InRuleReducerState {
  InRule: InRuleState;
}

export const inRuleInitialState: InRuleReducerState = {
  InRule: {},
};

export interface IStoreState {
  Policynumber: string;
  PASPolicyResponse: IPasPolicyState;
  QuoteServiceResponse: IQuoteServiceState;
  InRule: InRuleState;
}

export interface IMessageArray {
  Type: string;
  Code: string;
  Text: string;
}

export interface IIndicatorFlagsArray {
  Name: string;
  Value: boolean;
}
export interface IPoliciesArray {
  Id: string;
  PolicyRefNo: string;
  PolicyOwnership: string;
  State: string;
  InceptionDate: string;

  PolicyModalRebateAmt: number;
  PolicyModalAfterRebatePremiumAmt: number;
  PolicyYearlyCommissionablePremiumAmt: number;
  PolicyYearlyCommissionAmt: number;
  PolicyYearlyRenewalCommissionAmt: number;
  LastAnniversaryDate: string;
  LifPolicyIndicator: string;
  IndicatorFlags: IIndicatorFlagsArray[];
  PremiumAmounts: IPremiumAmountsArray[];
  RequestAction: string;
  UIObjects: IUIObjectsArray[];
}

export interface IPremiumAmountsArray {
  PremiumSetMode: string;
  BasePremiumAmt: number;
  StampDutyAmt: number;
  PolicyFeeAmt: number;
  TotalPremiumAmt: number;
  RequestAction: string;
  UIObjects?: IUIObjectsArray[];
}

export interface IValueListArray {
  Value: string;
  Code: string;
}

export interface IUIObjectsArray {
  Id: string;
  Source: string;
  Name: string;
  Label: string;
  ColWidth?: string;
  ObjectType: string;
  Value: string;
  ValueList?: IValueListArray[];
  Formatting: string;
  Frame: string;
  Group?: string;
  LabelGridCell?: string;
  ValueGridCell?: string;
  IsHidden: boolean;
  IsDisabled: boolean;
  IsUntouched: boolean;
  AllowedUserGroup: string;
}

export interface IOccupationNew {
  Code: string;
  Name: string;
  Description: string;
  Category: string;
  LifeRating: string;
  CIRating: string;
  TPDOccupationFactor: string;
  TPDOwnOccupationCode: string;
  TPDAnyOccupationCode: string;
  TPDADLOccupationCode: string;
  IPOccupationClassCode: string;
  Industry: string;
  PASCode: string;
  IncomeEarningCode: string;
  HasVolatileIncome: boolean;
  RequestAction: string;
  UIObjects: IUIObjectsArray[];
}

export interface IDisclosuresArray {
  Description: string;
  Value: string;
  EffectiveDate: string;
  ExpiryDate: string;
}

export interface IBenefitOptionsArray {
  CoverBenefitOption: string;
  SumInsured: number;
  WaitingPeriodWks: number;
  BenefitPeriodYrs: number;
  MinimumSumInsured: number;
  PreviousSumInsured: number;
  ReinsuranceSumInsured: number;
  ReinsuranceRetentionPct: number;
  RequestAction: string;
  UIObjects: IUIObjectsArray[];
}

export interface ILoadingsArray {
  Description: string;
  LoadingPct?: number;
  LoadingPerMille?: number;
  RequestAction: string;
  UIObjects?: IUIObjectsArray[];
}

export interface ILayersArray {
  Id: string;
  PolicyRefNo: string;
  AgeNextBirthday: number;
  SumInsured: number;
  NewSumInsured: number;
  InceptionDate: string;
  LayerMonthlyPremiumAmt: number;
  LayerRPOSumInsured: number;
  LayerDPOSumInsured: number;
  LayerBEXSumInsured: number;
  LayerRPOMonthlyPremiumAmt: number;
  LayerDPOMonthlyPremiumAmt: number;
  LayerBEXMonthlyPremiumAmt: number;
}

export interface IPremiumFactorsArray {
  Id: string;
  Code: string;
  Description: string;
  Category: string;
  Value: number;
  ValueType: string;
  FactorRequestAction: string;
}

export interface ICoversArray {
  Id: string;
  PolicyRefNo: string;
  CoverType: string;
  SumInsured: number;
  LinkedCoverType: string;
  LinkedParentCoverId: string;
  AgeNextBirthday: number;
  Gender: string;
  SmokerStatus: string;
  SelfEmploymentStatus: string;
  IPOccupationClassCode: string;
  TPDOccupationFactor: string;
  IsBMIDiscountEligible: boolean;
  HasPolicyFeeApplied: boolean;
  IsIndexed: boolean;
  IndexationPct: number;
  PolicyType: string;
  PolicyRatePct: number;
  RenewalPolicyRatePct: number;
  PremiumType: string;
  PremiumTypeTermNum: number;
  LevelOfCover: string;
  TPDDefinition: string;
  IPTypeOfReplacement: string;
  IPTypeOfCover: string;
  WaitingPeriodWks: number;
  BenefitPeriodYrs: number;
  InceptionDate: string;
  Disclosures: IDisclosuresArray[];
  CoverMonthlyPolicyFeeAmt: 0;
  CoverMonthlyStampDutyAmt: 0;
  CoverMonthlyPremiumAmt: 0;
  CoverModalAfterTaxPremiumAmt: 1131.74;
  CoverMonthlyCommissionablePremiumAmt: 1796.41;
  CoverYearlyCommissionAmt: 1185.63;
  CoverYearlyRenewalCommissionAmt: 395.21;
  InforceDate: "2001-01-01T00:00:00";
  BenefitCreationDate: "2001-01-01T00:00:00";
  PremiumWaiverInd: "Y";
  ReinsuranceTypeCode: "Y";
  ReinsuranceTreatyNum: "1";
  ReinsuranceSumInsured: 0;
  ReinsuranceReinsuredPct: 0;
  ReinsuranceCommissionPct: 0;
  PricingVersion: 1;
  PricingRateSet: "1";
  NewSumInsured: 0;
  MinimumSumInsured: 0;
  TotalSumInsured: 0;
  CoverMonthlyStage1PremiumAmt: 0;
  CoverMonthlyStage1StampDutyAmt: 0;
  CoverMonthlyStage2PremiumAmt: 0;
  CoverMonthlyStage2StampDutyAmt: 0;
  ReinsuranceMonthlyGrossPremiumAmt: 0;
  CoverMonthlyRPOPremiumAmt: 0;
  CoverMonthlyDPOPremiumAmt: 0;
  CoverMonthlyBEXPremiumAmt: 0;
  ReinsuranceRPOSumInsured: 0;
  ReinsuranceDPOSumInsured: 0;
  ReinsuranceBEXSumInsured: 0;
  IndicatorFlags: IIndicatorFlagsArray[];
  BenefitOptions: IBenefitOptionsArray[];
  Loadings: ILoadingsArray[];
  Layers: ILayersArray[];
  PremiumFactors: IPremiumFactorsArray[];
  PremiumAmounts: IPremiumAmountsArray[];
  RequestAction: string;
  UIObjects: IUIObjectsArray[];
}

export interface IPreviousCoversArray {
  Id: string;
  PolicyRefNo: string;
  CoverType: string;
  SumInsured: number;
  LinkedCoverType: string;
  LinkedParentCoverId: string;
  AgeNextBirthday: number;
  Gender: string;
  SmokerStatus: string;
  SelfEmploymentStatus: string;
  IPOccupationClassCode: string;
  TPDOccupationFactor: string;
  IsBMIDiscountEligible: boolean;
  HasPolicyFeeApplied: boolean;
  IsIndexed: boolean;
  IndexationPct: number;
  PolicyType: string;
  PolicyRatePct: number;
  RenewalPolicyRatePct: number;
  PremiumType: string;
  PremiumTypeTermNum: number;
  LevelOfCover: string;
  TPDDefinition: string;
  IPTypeOfReplacement: string;
  IPTypeOfCover: string;
  WaitingPeriodWks: number;
  BenefitPeriodYrs: number;
  InceptionDate: string;
}

export interface IAuxiliaryCoversArray {
  Id: string;
  PolicyRefNo: string;
  PricingVersion: number;
  PolicyOwnership: string;
  AgeNextBirthday: number;
  PremiumType: string;
  PremiumTypeTermNum: number;
  CoverType: string;
  SumInsured: number;
  MinimumSumInsured: number;
  WaitingPeriodWks: number;
  BenefitPeriodYrs: number;
  InceptionDate: string;
  LinkedCoverType: string;
}

export interface ILivesArray {
  Id: string;
  LifeSeqNum: number;
  PartyRefNo: string;
  AgeNextBirthday: number;
  Gender: string;
  SmokerStatus: string;
  State: string;
  SelfEmploymentStatus: string;
  AnnualIncomeAmt: number;
  AnnualSuperContributionAmt: number;
  AnnualIncomeLessSuperAmt: number;
  EmployerSuperGuaranteePct: number;
  PurposeOfCover: string;
  IsDiscountSelected: boolean;
  LifeModalTaxDeductablePremiumAmt: number;
  LifeYearlyCommissionablePremiumAmt: number;
  LifeYearlyCommissionAmt: number;
  LifeYearlyRenewalCommissionAmt: number;
  Occupation: IOccupationNew;
  HeightCm: number;
  WeightKg: number;
  BMIRating: number;
  IndicatorFlags: IIndicatorFlagsArray[];
  Covers: ICoversArray[];
  PreviousCovers: IPreviousCoversArray[];
  AuxiliaryCovers: IAuxiliaryCoversArray[];
  PremiumAmounts: IPremiumAmountsArray[];
  RequestAction: string;
  UIObjects: IUIObjectsArray[];
}

export interface IQuoteResponse {
  Id: string;
  QuoteRefNo: string;
  QuoteDate: string;
  QuoteYearlyCommissionablePremiumAmt: number;
  QuoteYearlyCommissionAmt: number;
  PricingVersionStatus: string;
  IndicatorFlags: IIndicatorFlagsArray[];
  Policies: IPoliciesArray[];
  Lives: ILivesArray[];
  PremiumAmounts: IPremiumAmountsArray[];
  UIObjects: IUIObjectsArray[];
}

export interface IPartyRolesArray {
  Type: string;
}

export interface IPartyUniqueIDsArray {
  Type: string;
  Value: string;
}

export interface IPartiesArray {
  Id: string;
  PartyRefNo: string;
  PartyRoles: IPartyRolesArray[];
  Title: string;
  FirstName: string;
  MiddleName?: string;
  LastName: string;
  BirthDate: string;
  OrganisationName: string;
  PartyUniqueIDs: IPartyUniqueIDsArray[];
  RequestAction: string;
  UIObjects: IUIObjectsArray[];
}

export interface IUIFramesArray {
  Id: string;
  Name: string;
  Label: string;
  UIFrameType: string;
}

export interface IUIGroupsArray {
  Id: string;
  Name: string;
  Label: string;
  Frame: string;
}

export interface IPolicyData {
  Id: string;
  RequestMethod: string;
  RequestType: string;
  RequestDate: string;
  CalculationDate: string;
  ProductCode: string;
  ProjectionYearRequestedNum: number;
  ProjectionIndexationPct: number;
  Messages: IMessageArray[];
  Channel: string;
  Brand: string;
  ProductSeries: string;
  SeriesVersion: string;
  UserRole: string;
  IndicatorFlags: IIndicatorFlagsArray[];
  Quote: IQuoteResponse;
  Parties: IPartiesArray[];
  UIFrames: IUIFramesArray[];
  UIGroups: IUIGroupsArray[];
  UIObjects: IUIObjectsArray[];
}

export interface IStoreState1 {
  data: IPolicyData;
}
