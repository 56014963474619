import gql from "graphql-tag";
import { client } from "../store/store";
import { applyQuoteLifeCoverModelRules } from "./inRuleService";

interface QueryParams {
  policyNumber?: string;
  clientId?: string;
  distributorClientNo?: string;
  distributorNo?: string;
  appRefNo?: string;
  lifeNo?: string;
  productSeries?: string;
  policyOrigin?: string;
  // productCode?: string;
}

const QueryFields = `
{
    Brand
    BusinessSource
    Channel
    UserRole
    Id
    RequestType
    RequestMethod
    RequestDate
    CalculationDate
    OriginCode
    ProductCode
    ProductSeries
    SeriesVersion
    ProjectionIndexationPct
    ProjectionYearRequestedNo
    Messages {
      Code
      Text
      Type
    }
    IndicatorFlags {
      Name
      Value
    }
    UIFrames {
      Id
      Name
      Label
    }
    UIGroups {
      Id
    }

    Parties {
      Id
      PartyRefNo
      PartyRoles {
        Type
      }
      Title
      FirstName
      MiddleName
      LastName
      BirthDate
      OrganisationName
      PartyIds {
        Name
        Value
      }
      RequestAction
      InitialValues {
        BirthDate
      }
    }
    Quote {
      Id
      QuoteRefNo
      QuoteDate
      IndicatorFlags {
        Name
        Value
      }
      PricingVersionStatus
      PremiumAmounts {
        PremiumSetMode
        BasePremiumAmt
        StampDutyAmt
        PolicyFeeAmt
        TotalPremiumAmt
      }
      PremiumAmountSummary {
        QuoteYearlyCommissionablePremiumAmt
        QuoteYearlyCommissionAmt
      }
      UIObjects {
        Id
        Source
        Name
        Label
        Value
        ValueList {
          Value
          Code
          IsHidden
        }
        ObjectType
        Formatting
        FrameName
        GroupName
        LabelGridCell
        ValueGridCell
        LabelGridCellWidth
        ValueGridCellWidth
        IsHidden
        IsDisabled
        AllowedUserGroup
        HiddenUserGroup
        IsChanged
        IsMandatory
        TriggerAction {
          Code
          FactorRequestAction
        }
        Message {
          Type
          Code
          Text
        }
      }
      Policies {
        Id
        PolicyRefNo
        PartyRefNo
        PolicyOwnership
        State
        PaymentFrequencyMos
        InceptionDate
        LastAnniversaryDate
        LifPolicyIndicator
        SeriesVersion
        PaymentMethod
        ContributionType
        RiskContractDate
        RiskTermYrs
        IndicatorFlags {
          Name
          Value
        }
        FlexFields{
            Name
            Value
            Amount
            Date             
          }
        PremiumAmounts {
          PremiumSetMode
          BasePremiumAmt
          StampDutyAmt
          PolicyFeeAmt
          TotalPremiumAmt
        }
        PremiumAmountSummary {
          PolicyModalRebateAmt
          PolicyModalAfterRebatePremiumAmt
          PolicyYearlyCommissionablePremiumAmt
          PolicyYearlyCommissionAmt
          PolicyYearlyRenewalCommissionAmt
        }
        RequestAction
        InitialValues {
          PolicyOwnership
          State
          PaymentFrequencyMos
          InceptionDate
          LastAnniversaryDate
          PremiumAmounts {
            PremiumSetMode
            BasePremiumAmt
            StampDutyAmt
            PolicyFeeAmt
            TotalPremiumAmt
          }
          IndicatorFlags {
            Name
            Value
          }            
          UIObjects {
            Id
            Source
            Name
            Label
            Value
            ValueList {
              Value
              Code
              IsHidden
            }
            ObjectType
            Formatting
            FrameName
            GroupName
            LabelGridCell
            ValueGridCell
            LabelGridCellWidth
            ValueGridCellWidth
            IsHidden
            IsDisabled
            AllowedUserGroup
            HiddenUserGroup
            IsMandatory
            TriggerAction {
              Code
              FactorRequestAction
            }
            Message {
              Type
              Code
              Text
            }
          }
        }
        UIObjects {
          Id
          Source
          Name
          Label
          Value
          ValueList {
            Value
            Code
            IsHidden
          }
          ObjectType
          Formatting
          FrameName
          GroupName
          LabelGridCell
          ValueGridCell
          LabelGridCellWidth
          ValueGridCellWidth
          IsHidden
          IsDisabled
          AllowedUserGroup
          HiddenUserGroup
          IsChanged
          IsMandatory
          TriggerAction {
            Code
            FactorRequestAction
          }
          Message {
            Type
            Code
            Text
          }
        }
      }
      Lives {
        Id
        LifeSeqNo
        PartyRefNo
        AgeNextBirthday
        Gender
        SmokerStatus
        State
        SelfEmploymentStatus
        AnnualIncomeAmt
        EmployerSuperContributionAmt
        AnnualIncomeLessSuperAmt
        EmployerSuperGuaranteePct
        PurposeOfCover
        IsDiscountSelected
        Projections {
          TotalAnnualisedPremiumAmt
        }
        Occupation {
          Code
          Name
          Description
          Category
          LifeRating
          CIRating
          TPDOccupationFactor
          TPDOwnOccupationCode
          TPDAnyOccupationCode
          TPDADLOccupationCode
          IPOccupationClassCode
          Industry
          PASCode
          IncomeEarningCode
          HasVolatileIncome
        }
        HeightCm
        WeightKg
        BMI
        IndicatorFlags {
          Name
          Value
        }
        FlexFields {
          Name
          Value
          Amount
          Date
        }
        PremiumAmounts {
          PremiumSetMode
          BasePremiumAmt
          StampDutyAmt
          PolicyFeeAmt
          TotalPremiumAmt
        }
        PremiumAmountSummary {
          LifeModalTaxDeductablePremiumAmt
          LifeYearlyCommissionablePremiumAmt
          LifeYearlyCommissionAmt
          LifeYearlyRenewalCommissionAmt
        }
        RequestAction
        InitialValues {
          AgeNextBirthday
          Gender
          SmokerStatus
          State
          SelfEmploymentStatus
          AnnualIncomeAmt
          EmployerSuperContributionAmt
          AnnualIncomeLessSuperAmt
          EmployerSuperGuaranteePct
          Occupation {
            Code
            Name
            Description
            Category
            LifeRating
            CIRating
            TPDOccupationFactor
            TPDOwnOccupationCode
            TPDAnyOccupationCode
            TPDADLOccupationCode
            IPOccupationClassCode
            Industry
            PASCode
            IncomeEarningCode
            HasVolatileIncome
          }
          PremiumAmounts {
            PremiumSetMode
            BasePremiumAmt
            StampDutyAmt
            PolicyFeeAmt
            TotalPremiumAmt
          }
          IndicatorFlags {
             Name
            Value
          }
          FlexFields{
            Name
            Value
            Amount
            Date
          }
          UIObjects {
            Id
            Source
            Name
            Label
            Value
            ValueList {
              Value
              Code
              IsHidden
            }
            ObjectType
            Formatting
            FrameName
            GroupName
            LabelGridCell
            ValueGridCell
            LabelGridCellWidth
            ValueGridCellWidth
            IsHidden
            IsDisabled
            AllowedUserGroup
            HiddenUserGroup
            IsChanged
            IsMandatory
            TriggerAction {
              Code
              FactorRequestAction
            }
            Message {
              Type
              Code
              Text
            }
          }
        }
        UIObjects {
          Id
          Source
          Name
          Label
          Value
          ValueList {
            Value
            Code
            IsHidden
          }
          ObjectType
          Formatting
          FrameName
          GroupName
          LabelGridCell
          ValueGridCell
          LabelGridCellWidth
          ValueGridCellWidth
          IsHidden
          IsDisabled
          AllowedUserGroup
          HiddenUserGroup
          IsChanged
          IsMandatory
          TriggerAction {
            Code
            FactorRequestAction
          }
          Message {
            Type
            Code
            Text
          }
        }
        Covers {
          Id
          PolicyRefNo
          CoverType
          SumInsured
          LinkedCoverType
          LinkedParentCoverId
          AgeNextBirthday
          Gender
          SmokerStatus
          SelfEmploymentStatus
          IPOccupationClassCode
          TPDOccupationFactor
          IsBMIDiscountEligible
          HasPolicyFeeApplied
          IsIndexed
          IndexationPct
          PolicyType
          PolicyRatePct
          RenewalPolicyRatePct
          PremiumType
          PremiumTypeTermNo
          LevelOfCover
          TPDDefinition
          IPTypeOfReplacement
          IPTypeOfCover
          WaitingPeriodWks
          BenefitPeriodYrs
          InceptionDate
          InforceDate
          BenefitCreationDate
          ReinsuranceType
          ReinsuranceTreatyNo
          ReinsuranceSumInsured
          ReinsuranceReinsuredPct
          ReinsuranceCommissionPct
          PricingVersion
          PricingRateSet
          NewSumInsured
          MinimumSumInsured
          TotalSumInsured
          PremiumWaiverInd
          CoverMonthlyPolicyFeeAmt
          CoverMonthlyStampDutyAmt
          CoverMonthlyPremiumAmt
          CoverMonthlyStage1PremiumAmt
          CoverMonthlyStage1StampDutyAmt
          CoverMonthlyStage2PremiumAmt
          CoverMonthlyStage2StampDutyAmt
          ReinsuranceMonthlyGrossPremiumAmt
          CoverMonthlyRPOPremiumAmt
          CoverMonthlyDPOPremiumAmt
          CoverMonthlyBEXPremiumAmt
          ReinsuranceRPOSumInsured
          ReinsuranceDPOSumInsured
          ReinsuranceBEXSumInsured
          IsDepreciating
          IndicatorFlags {
            Name
            Value
          }
          Exclusions{
            Code
            Description
            Category
            Term
            EffectiveDate
            ExpiryDate
            RequestAction
          }
          FlexFields {
            Name
            Value
            Amount
            Date
          }
          BenefitOptions {
            CoverBenefitOption
            SumInsured
            WaitingPeriodWks
            BenefitPeriodYrs
            MinimumSumInsured
            PreviousSumInsured
            ReinsuranceSumInsured
            ReinsuranceRetentionPct
            RequestAction
          }
          Loadings {
            CoverType
            Description
            LoadingPct
            LoadingPerMille
            ExpiryDate
            RequestAction
          }
          Disclosures {
            Description
            Value
            EffectiveDate
            ExpiryDate
            RequestAction
          }
          Layers {
            Id
            PolicyRefNo
            AgeNextBirthday
            SumInsured
            NewSumInsured
            InceptionDate
            BenefitOptions {
              CoverBenefitOption
              SumInsured
              WaitingPeriodWks
              BenefitPeriodYrs
              MinimumSumInsured
              PreviousSumInsured
              ReinsuranceSumInsured
              ReinsuranceRetentionPct
              RequestAction
            }           
            LayerMonthlyPremiumAmt
            LayerRPOSumInsured
            LayerDPOSumInsured
            LayerBEXSumInsured
            LayerRPOMonthlyPremiumAmt
            LayerDPOMonthlyPremiumAmt
            LayerBEXMonthlyPremiumAmt
          }
          PremiumFactors {
            Id
            Code
            Description
            Category
            Value
            ValueType
            EffectiveDate
            ExpiryDate
            MonthlyTerm
            PreviousValue
            IndicatorFlags {
              Name
              Value
            }
            FactorRequestAction
            RequestAction
          }
          PremiumAmounts {
            PremiumSetMode
            BasePremiumAmt
            StampDutyAmt
            PolicyFeeAmt
            TotalPremiumAmt
          }
          PremiumAmountSummary {
            CoverModalAfterTaxPremiumAmt
            CoverMonthlyCommissionablePremiumAmt
            CoverYearlyCommissionAmt
            CoverYearlyRenewalCommissionAmt
          }
          RequestAction
          InitialValues {
            SumInsured
            MinimumSumInsured
            IPOccupationClassCode
            TPDOccupationFactor
            IsIndexed
            PolicyType
            PolicyRatePct
            RenewalPolicyRatePct
            PremiumType
            PremiumTypeTermNo
            LevelOfCover
            TPDDefinition
            IPTypeOfReplacement
            IPTypeOfCover
            WaitingPeriodWks
            BenefitPeriodYrs
            InceptionDate
            BenefitOptions {
              CoverBenefitOption
              SumInsured
              WaitingPeriodWks
              BenefitPeriodYrs
              MinimumSumInsured
              PreviousSumInsured
              ReinsuranceSumInsured
              ReinsuranceRetentionPct
              RequestAction
            }
            Layers {
            Id
            PolicyRefNo
            AgeNextBirthday
            SumInsured
            NewSumInsured
            InceptionDate
            BenefitOptions {
              CoverBenefitOption
              SumInsured
              WaitingPeriodWks
              BenefitPeriodYrs
              MinimumSumInsured
              PreviousSumInsured
              ReinsuranceSumInsured
              ReinsuranceRetentionPct
              RequestAction
            }            
            LayerMonthlyPremiumAmt
            LayerRPOSumInsured
            LayerDPOSumInsured
            LayerBEXSumInsured
            LayerRPOMonthlyPremiumAmt
            LayerDPOMonthlyPremiumAmt
            LayerBEXMonthlyPremiumAmt
          }
            Loadings {
              CoverType
              Description
              LoadingPct
              LoadingPerMille
              ExpiryDate
              RequestAction
            }
            PremiumAmounts {
              PremiumSetMode
              BasePremiumAmt
              StampDutyAmt
              PolicyFeeAmt
              TotalPremiumAmt
            }
            PremiumFactors {
              Id
              Code
              Description
              Category
              Value
              ValueType
              EffectiveDate
              ExpiryDate
              MonthlyTerm
              IndicatorFlags{
                Name
                Value
              }
              FactorRequestAction
            }
            IndicatorFlags {
              Name
              Value
            }
            FlexFields{
              Name
              Value
              Amount  
              Date           
            }
            UIObjects {
              Id
              Source
              Name
              Label
              Value
              ValueList {
                Value
                Code
                IsHidden
              }
              ObjectType
              Formatting
              FrameName
              GroupName
              LabelGridCell
              ValueGridCell
              LabelGridCellWidth
              ValueGridCellWidth
              IsHidden
              IsDisabled
              AllowedUserGroup
              HiddenUserGroup
              IsChanged
              IsMandatory
              TriggerAction {
                Code
                FactorRequestAction
              }
              Message {
                Type
                Code
                Text
              }
            }
          }
          UIObjects {
            Id
            Source
            Name
            Label
            Value
            ValueList {
              Value
              Code
              IsHidden
            }
            ObjectType
            Formatting
            FrameName
            GroupName
            LabelGridCell
            ValueGridCell
            LabelGridCellWidth
            ValueGridCellWidth
            IsHidden
            IsDisabled
            AllowedUserGroup
            HiddenUserGroup
            IsChanged
            IsMandatory
            TriggerAction {
              Code
              FactorRequestAction
            }
            Message {
              Type
              Code
              Text
            }
          }
        }
        PreviousCovers {
          Id
          PolicyRefNo
          CoverType
          SumInsured
          LinkedCoverType
          LinkedParentCoverId
          AgeNextBirthday
          Gender
          SmokerStatus
          SelfEmploymentStatus
          IPOccupationClassCode
          TPDOccupationFactor
          IsBMIDiscountEligible
          HasPolicyFeeApplied
          IsIndexed
          IndexationPct
          PolicyType
          PolicyRatePct
          RenewalPolicyRatePct
          PremiumType
          PremiumTypeTermNo
          LevelOfCover
          TPDDefinition
          IPTypeOfReplacement
          IPTypeOfCover
          WaitingPeriodWks
          BenefitPeriodYrs
          InceptionDate
          PremiumWaiverInd
          CoverMonthlyPolicyFeeAmt
          CoverMonthlyStampDutyAmt
          CoverMonthlyPremiumAmt
          IsDepreciating
          BenefitOptions {
            CoverBenefitOption
            SumInsured
            WaitingPeriodWks
            BenefitPeriodYrs
            MinimumSumInsured
            PreviousSumInsured
            ReinsuranceSumInsured
            ReinsuranceRetentionPct
            RequestAction
          }
          Loadings {
            CoverType
            Description
            LoadingPct
            LoadingPerMille
            ExpiryDate
            RequestAction
          }
          PremiumFactors {
            Id
            Code
            Description
            Category
            Value
            ValueType
            EffectiveDate
            ExpiryDate
            MonthlyTerm
            IndicatorFlags {
              Name
              Value
            }
            FactorRequestAction
          }
        }
        AuxiliaryCovers {
          Id
          PolicyRefNo
          PricingVersion
          PolicyOwnership
          AgeNextBirthday
          IPOccupationClassCode
          TPDOccupationFactor
          IPTypeOfReplacement
          PremiumType
          PremiumTypeTermNo
          CoverType
          SumInsured
          MinimumSumInsured
          WaitingPeriodWks
          BenefitPeriodYrs
          InceptionDate
          LinkedCoverType
          LinkedParentCoverId
          BenefitOptions {
            CoverBenefitOption
            SumInsured
            WaitingPeriodWks
            BenefitPeriodYrs
            MinimumSumInsured
            PreviousSumInsured
            ReinsuranceSumInsured
            ReinsuranceRetentionPct
            RequestAction
          }
          Layers {
            Id
            PolicyRefNo
            AgeNextBirthday
            SumInsured
            NewSumInsured
            InceptionDate
            BenefitOptions {
              CoverBenefitOption
              SumInsured
              WaitingPeriodWks
              BenefitPeriodYrs
              MinimumSumInsured
              PreviousSumInsured
              ReinsuranceSumInsured
              ReinsuranceRetentionPct
              RequestAction
            }
            LayerMonthlyPremiumAmt
            LayerRPOSumInsured
            LayerDPOSumInsured
            LayerBEXSumInsured
            LayerRPOMonthlyPremiumAmt
            LayerDPOMonthlyPremiumAmt
            LayerBEXMonthlyPremiumAmt
          }
        }
      }
    }
  }
`;

function getUserGroupDetails() {
  const tokenGroups = JSON.parse(
    localStorage.getItem("okta-token-storage") as any
  );
  return tokenGroups?.idToken?.claims?.groups;
}
const queryParams: QueryParams = {
  policyNumber: new URL(window.location.href).searchParams.get(
    "policyNumber"
  ) as string,
  clientId: new URL(window.location.href).searchParams.get(
    "clientId"
  ) as string,
  distributorClientNo: new URL(window.location.href).searchParams.get(
    "distributorClientNo"
  ) as string,
  distributorNo: new URL(window.location.href).searchParams.get(
    "distributorNo"
  ) as string,
  appRefNo: new URL(window.location.href).searchParams.get(
    "appRefNo"
  ) as string,
  lifeNo: new URL(window.location.href).searchParams.get("lifeNo") as string,
  productSeries: new URL(window.location.href).searchParams.get(
    "productSeries"
  ) as string,
  policyOrigin: new URL(window.location.href).searchParams.get(
    "policyOrigin"
  ) as string,
  // productCode: new URL(window.location.href).searchParams.get(
  //   "productCode"
  // ) as string
};
function getdistributorNumber() {
  const queryParams: QueryParams = {
    policyNumber: new URL(window.location.href).searchParams.get(
      "policyNumber"
    ) as string,
    clientId: new URL(window.location.href).searchParams.get(
      "clientId"
    ) as string,
    distributorClientNo: new URL(window.location.href).searchParams.get(
      "distributorClientNo"
    ) as string,
    distributorNo: new URL(window.location.href).searchParams.get(
      "distributorNo"
    ) as string,
    appRefNo: new URL(window.location.href).searchParams.get(
      "appRefNo"
    ) as string,
    lifeNo: new URL(window.location.href).searchParams.get("lifeNo") as string,
    productSeries: new URL(window.location.href).searchParams.get(
      "productSeries"
    ) as string,
    policyOrigin: new URL(window.location.href).searchParams.get(
      "policyOrigin"
    ) as string,
    // productCode: new URL(window.location.href).searchParams.get(
    //   "productCode"
    // ) as string
  };

  const tokenGroups = JSON.parse(
    localStorage.getItem("okta-token-storage") as any
  );
  if (
    tokenGroups?.idToken?.claims?.distributorSettings !== null ||
    tokenGroups?.idToken?.claims?.distributorSettings !== undefined ||
    tokenGroups?.idToken?.claims?.distributorSettings !== ""
  ) {
    var splitDistributorSettings =
      tokenGroups?.idToken?.claims?.distributorSettings[0].split("=");
    return splitDistributorSettings[0];
  } else {
    return queryParams.distributorNo;
  }
}

export const getPolicyDetails = async (policyNumber: string) => {
  //var userGroupRoles = getUserGroupDetails();
  //var adviserNumber = getdistributorNumber();

  const variables = {
    policySearchRequest: {
      policyNumber: parseInt(policyNumber),
      //userRoles: userGroupRoles.join(),
      //userRoles: ".uEBQAllStaffQA,.uEBQCrtQA",
      //Todo: comment out these to in both External and Internal
      // userRoles: getUserGroups(),
      // distributorNumber: queryParams.distributorNo
    },
  };

  const policyDetailsQuery = gql`query ($policySearchRequest: PasPolicyRequest!) {
    getpolicydetails(policySearchRequest: $policySearchRequest)${QueryFields}}`;
  // Construct headers object
  const headers = {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_SubscriptionKey,
    "o-ac": "",
    "o-id": "",
  };

  // Add OKTA header if OKTA token is available
  const tokenGroups = JSON.parse(
    localStorage.getItem("okta-token-storage") as any
  );

  let OACValue: HTMLElement | null = document.getElementById("oAc");
  let OIDValue: HTMLElement | null = document.getElementById("oId");
  //let OACValue : string | null= readCookie("OKTA_ACCESS_TOKEN");
  //let OIDValue : string | null= readCookie("OKTA_ID_TOKEN");
  console.log(`token Groups from store is: ${tokenGroups}`);
  if (OACValue && OIDValue) {
    //tokenGroups.idToken.idToken
    //tokenGroups.accessToken.accessToken
    headers["o-ac"] = OACValue.innerHTML;
    headers["o-id"] = OIDValue.innerHTML;
  } else if (tokenGroups) {
    headers["o-ac"] = tokenGroups.accessToken.accessToken;
    headers["o-id"] = tokenGroups.idToken.idToken;
  }

  return Promise.resolve(
    client.query({
      query: policyDetailsQuery,
      variables: variables,
      context: {
        headers: headers,
      },
    })
  )
};

function readCookie(name: string): string | null {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}

const getUserGroups = () => {
  const spanForUserGroups = document.getElementById("oktaUserGroup");
  if (spanForUserGroups) {
    return (
      spanForUserGroups.textContent ||
      spanForUserGroups.innerText ||
      spanForUserGroups.innerHTML
    );
  } else {
    var userGroupRoles = getUserGroupDetails();
    return userGroupRoles.join();
  }
  // oktaUserGroup
};

export const getAssociatedPolicyDetails = async (
  policyNumber: string,
  quoteModel: any
) => {
  //   const headers = {
  //     "o-ac":"",
  //     "o-id":""
  //   };

  //   // Add OKTA header if OKTA token is available
  //   const tokenGroups = JSON.parse(
  //     localStorage.getItem("okta-token-storage") as any
  //   );

  //   let OACValue : HTMLElement | null= document.getElementById("oAc");
  //    let OIDValue : HTMLElement | null= document.getElementById("oId");
  //   //let OACValue : string | null= readCookie("OKTA_ACCESS_TOKEN");
  //  // let OIDValue : string | null= readCookie("OKTA_ID_TOKEN");

  //   console.log(`token Groups from store is: ${tokenGroups}`);
  //   if (OACValue && OIDValue) {
  //     headers["o-ac"] = OACValue.innerHTML;
  //     headers["o-id"] = OIDValue.innerHTML;

  //   }
  //   else if(tokenGroups){
  //     headers["o-ac"] = tokenGroups.accessToken.accessToken;
  //     headers["o-id"] = tokenGroups.idToken.idToken;
  //   }
  const variables = {
    policyNumber: parseInt(policyNumber),
    quoteModel: quoteModel,
  };

  const policyDetailsQuery = gql`query ($policyNumber: Int!, $quoteModel: QuoteUnifiedInputModel!) {
    getAssociatedPolicyDetails(policyNumber: $policyNumber, quoteModel: $quoteModel)${QueryFields}}`;

  return Promise.resolve(
    client.query({
      query: policyDetailsQuery, // change this to lookup table array like policyDetailsQueryMock["policyNum"]
      variables: variables,
      // context: {
      //   headers: headers
      // }
    })
  );
};

export const getQuoteIllustrationDocument = async (
  quotemodel: any,
  isAdviserHide: string, 
  isBenefitProjection: boolean, 
  isPremiumProjection: boolean,
  projectionDuration: number
) => {
  //Added isAdviserHide as query string

  const uri = `${process.env.REACT_APP_CorroService_Uri}?isAdviserHide=${isAdviserHide || ""}&isBenefitProjection=${isBenefitProjection}&isPremiumProjection=${isPremiumProjection}&projectionDuration=${projectionDuration}`
  //   process.env.REACT_APP_CorroService_Uri +
  //     "?isAdviserHide=" +
  //     isAdviserHide  + 
  //     "isBenefitProjection=" +      
  //     isBenefitProjection 
  //     "isPremiumProjection=" +
  //     isPremiumProjection
  //     "projectionDuration=" +
  //     projectionDuration;
  console.log(uri) 
  return Promise.resolve(
    fetch(uri, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key":
          process.env["REACT_APP_SubscriptionKey"] || "",
      },
      body: JSON.stringify(quotemodel),
    }).then(async (response) => {
      if (response.status !== 200) {
        throw new Error(await response.text());
      }
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `QuoteIllustration_${quotemodel.Quote.QuoteRefNo}.pdf`;
        a.click();
      });
    })
 );
};

export const updateQuote = (data: any) => {
  // data.RequestMethod = "GetPremiumNoValidation";
  // data.Messages = [{}];
  let query: any = null;
  let variables;
  // DGOTO 3970 added condition to differntiate add cover
  if (data.isUpdateQuoteModel) {
    variables = {
      getQuoteModelRequest: data,
    };
    query = gql`query ($getQuoteModelRequest: QuoteUnifiedInputModel!) {
    getupdatedquotemodeldetails(getQuoteModelRequest: $getQuoteModelRequest)${QueryFields}}`;
  } else {
    variables = {
      getPremiumRequest: data,
    };
    query = gql`query ($getPremiumRequest: QuoteUnifiedInputModel!) {
    getupdatedpremiumdetails(getPremiumRequest: $getPremiumRequest)${QueryFields}}`;
  }

  if (process.env?.REACT_APP_ENV_Stage === "true") {
    console.log("updateQuote GQL", variables, "query", query);
  }
  const headers = {
    "o-ac": "",
    "o-id": "",
  };

  // Add OKTA header if OKTA token is available
  const tokenGroups = JSON.parse(
    localStorage.getItem("okta-token-storage") as any
  );

  let OACValue: HTMLElement | null = document.getElementById("oAc");
  let OIDValue: HTMLElement | null = document.getElementById("oId");
  //let OACValue : string | null= readCookie("OKTA_ACCESS_TOKEN");
  // let OIDValue : string | null= readCookie("OKTA_ID_TOKEN");

  console.log(`token Groups from store is: ${tokenGroups}`);
  if (OACValue && OIDValue) {
    headers["o-ac"] = OACValue.innerHTML;
    headers["o-id"] = OIDValue.innerHTML;
  } else if (tokenGroups) {
    headers["o-ac"] = tokenGroups.accessToken.accessToken;
    headers["o-id"] = tokenGroups.idToken.idToken;
  }
  // DGOTO 3970 added condition to differntiate add cove
  if (data.isUpdateQuoteModel) {
    delete variables?.getQuoteModelRequest?.isUpdateQuoteModel;
    let result =Promise.resolve(
      client.query({
        query: query,
        variables: variables,
        context: {
          headers: headers,
        },
      })
    );
    result.then((response) => {

    if(response?.data?.getupdatedquotemodeldetails.Quote !== null ){
      //if (response?.data?.getupdatedquotemodeldetails?.Messages !== undefined) {
        //if (response?.data?.getupdatedquotemodeldetails?.Messages != null)
           applyQuoteLifeCoverModelRules(response.data.getupdatedquotemodeldetails);

       //}
      }
      })
      return result;
  } else {
    delete variables?.getPremiumRequest?.isUpdateQuoteModel;
    
    let result = Promise.resolve(
      client.query({
        query: query,
        variables: variables,
        context: {
          headers: headers,
        },
      })
    );
    result.then((response) => {
          // if (
          //   response.data.getupdatedpremiumdetails.Messages == null
          // )
          if(response?.data?.getupdatedpremiumdetails.Quote !== null ){
            applyQuoteLifeCoverModelRules(
              response.data.getupdatedpremiumdetails
            );
          }
    })
    return result;
  }
};
