import { GelContainerLite, GelRow } from "@tal-gel/components";
import CardFrame from "../shared/CardFrame";

interface CoverDetailsData {
  cover: any;
  UpdateQuoteCallback: any;
  UIObjects: any;
  policyData?: any;
  inruleDataState?: any;
  grandParentObject?: any;
}
const CoverDetails = ({
  cover,
  UIObjects,
  UpdateQuoteCallback,
  policyData,
  inruleDataState,
  grandParentObject
}: CoverDetailsData) => {
  const listA = UIObjects.filter(
    (item: any) => item.LabelGridCell.substr(0, 1) === "A"
  );

  const listB = UIObjects.filter(
    (item: any) => item.LabelGridCell.substr(0, 1) === "B"
  )
  // .sort(
  //   (a: any, b: any) =>
  //     a.LabelGridCell.substr(a.LabelGridCell.length - 1) -
  //     b.LabelGridCell.substr(b.LabelGridCell.length - 1)
  // );

  return (
    <GelContainerLite fluid gutter={"xsmall"}>
      <GelRow>
        <CardFrame
          cover={cover}
          list={listA}
          UpdateQuoteFn={UpdateQuoteCallback}
          policyData={policyData}
          inruleDataState={inruleDataState}
          grandParentObject={grandParentObject}
        />
        <CardFrame
          cover={cover}
          list={listB}
          UpdateQuoteFn={UpdateQuoteCallback}
          policyData={policyData}
          inruleDataState={inruleDataState}
          grandParentObject={grandParentObject}
        />
      </GelRow>
    </GelContainerLite>
  );
};
export default CoverDetails;
